@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/animations';
.Game {
	height: 100%;

	.Game-cookieBtn {
		position: absolute;
		z-index: 9;
		bottom: 0.5em;
		left: 0.5em;
		width: 3em;
		height: 3em;
		background-image: url(../../assets/images/icons/icon-cookies.svg);
	}
}