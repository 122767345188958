@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/buttons';
@import '../styles/animations';
.App {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
	&.shake {
		@include shake();
	}
}