/* Colors */
$black: #000000;
$white: #ffffff;

$blue: #697690;
$blueDark: #273262;
$blueSky: #00B3CA;

$red: #DD3426;
$redDark: #FB6453;
$green: #8DB126;

$textDark: #68778F;
$textDark2: #222E5B;

// old colors
$blueSteel: #8EA2A8;
$blueLight: #ADDEFE;



// Order engine, item height
$orderItemHeight: 5em;