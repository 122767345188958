@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.Instructions {
	position: relative;
	height: 100%;
	overflow-x: hidden;

	.Instructions-overlay {
		position: absolute;
		pointer-events: none;
		height: 100%;
		width: 100%;
		background-image: 
			url(../../../../../assets/images/backgrounds/outside/endgame.svg),
			linear-gradient(to bottom, #1F2850, rgba(#1F2850, 0) 60%)
		;
		background-size: 
			24em auto, 
			100% 100%
		;
		background-position: top 5.7em center, top center;

		&.portrait {
			background-size:
				18em auto,
				100% 100%
			;
		}

		.Instructions-stars {
			position: absolute;
			width: 100%;
			top: 20%;
			gap: 0 1.7em;
			@include flex('center');

			.Instructions-star {
				width: 2.7em;
				height: 2.6em;
				background-image: url(../../../../../assets/images/icons/icon-star.svg);

				&.filled {
					background-image: url(../../../../../assets/images/icons/icon-star-filled.svg);
				}
			}
		}
	}

	.Instructions-content {
		.Instructions-title {
			span {
				font-weight: 700;
				font-size: 1.5em;
				margin-bottom: 0.75em;
			}
		}
		.Instructions-text {
			margin-right: 1.4em;
			p {
				font-size: 1.25em;
				color: $textDark;
				font-weight: 500;
				margin-bottom: 0;
				&:first-of-type {margin-top: 0;}
			}
		}
		.Instructions-audio {
			position: absolute;
			top: calc(50% - (0.5 * 2.25em));
			right: 0.5em;
		}
	}

	.Instructions-character {
		position: absolute;
		bottom: 0;
		overflow: hidden;
		&.manager {
			left: calc(50% - 20em);
			height: 27.5em;
		}
	}

	&.portrait {
		.Instructions-character {
			&.manager {
				left: calc(50% - 17.25em);
				height: 30em;
			}
		}
	}
}