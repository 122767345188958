@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrderPush {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: hidden;

	.OrderPush-intro {
		position: relative;
		width: 55.56em;
		max-width: calc(100% - 2em);
		background-color: rgba(white, 0.8);
		border-radius: 0.5em;
		margin: 2em auto 0.75em auto;
	}

	.OrderPush-items {
		position: relative;
		@include flex('center');
	}

	.OrderPush-backgroundContainer {
		position: absolute;
		@include no-select();
		top: 0;
		width: 100%;
		height: 100%;
	}

	.OrderPush-doneBtn {
		position: absolute;
		right: calc(50% - 8.9em / 2);
		bottom: 1em;
		z-index: 2;
		width: 8.9em;
	}
}