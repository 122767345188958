@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.SortDndDragLayer {
	.SortDndDragLayer-item {
		position: relative;
		width: 20em;
		height: 3em;
		padding: 0 2.75em 0 2.5em;
		@include button-blue();
		@include flex('flex-start', 'center');
		span {
			color: white;
			font-size: 1.1em;
			font-weight: 700;
			@include no-select();
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 2.5em;
			height: 100%;
			background-image: url('../../../../../assets/images/icons/icon-drag.svg');
			background-size: auto 1.33em;
			background-position: center center;
		}
	}
}