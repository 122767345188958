@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrderPushDndGraphic {
	pointer-events: none;
	position: relative;
	width: 100%;
	height: $orderItemHeight;
	line-height: 1.25;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	@include flex('center', 'center');
	@include no-select();
	@include button-blue-dark();
	padding: 0.75em 3em 0.5em 3em;

	&.animated {
		// @include orderDropAnimation();
	}

	span {
		color: $white;
		font-size: 1.07em;
		font-weight: bold;
	}

	&.correct {
		@include button-green();
	}

	.OrderPushDndGraphic-audio {
		position: absolute;
		right: 0.5em;
		top: calc(50% - 1em);
	}

	.OrderPushDndGraphic-handle {
		position: absolute;
		height: 100%;
		width: 3em;
		left: 0;
		top: 0;
		background-image: url('../../../../../assets/images/icons/icon-drag.svg');
		background-position: right 0.4em center;
		background-size: auto 1.8em;
		background-repeat: no-repeat;
		cursor: move;
		&.disabled {
			cursor: not-allowed;
			background-image: none;
		}
	}
}