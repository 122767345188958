@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.AdminRemoveFacilitatorPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');

	.AdminRemoveFacilitatorPopup-content {
		width: 25em;
		background-color: white;
		border-radius: 0.83em;

		.AdminRemoveFacilitatorPopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #f2482b;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			padding-left: 2em;
			@include flex('flex-start', 'center');
			.AdminRemoveFacilitatorPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.AdminRemoveFacilitatorPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../assets/images/icons/icon-cross-white.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.AdminRemoveFacilitatorPopup-body {
			padding: 1.5em;
			text-align: left;
			.AdminRemoveFacilitatorPopup-text {
				width: 100%;
				color: #525252;			
				
			}
			.AdminRemoveFacilitatorPopup-feedback {
				width: 100%;
				height: 2em;
				span {
					font-size: 0.8em;
					color: #525252;
				}
				&.error span {color: #C13B3B;}
			}
			.AdminRemoveFacilitatorPopup-buttons {
				width: 100%;
				@include flex('space-between', 'center');
				.AdminRemoveFacilitatorPopup-confirmBtn,
				.AdminRemoveFacilitatorPopup-cancelBtn {
					width: 10em;
				}
			}
			
		}
	}
}