@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/animations';
.Loading {
	height: 100%;
	.Loading-panel {
		position: relative;
		height: 100%;
    width: 100%;
		text-align: center;
		padding: 3.5em 2.4em;
		@include flex('center', 'center');

		.Loading-logoutBtn {
			position: absolute;
			bottom: 1em;
			left: 1em;
		}
		.Loading-content {
			.Loading-circles {
				width: 100%;
				margin-bottom: 1em;
				@include flex('center', 'center');
				.Loading-circle {
					width: 2em;
					height: 2em;
					margin: 0 0.5em;
					background-color: rgba($white, 1);
					border-radius: 2em;
				}
			}
			.Loading-text {
				font-size: 1.5em;
				color: $white;
			}
		}
	}
}

@for $i from 1 to 4 {
	.Loading-circle:nth-child(#{$i}) {
		animation: loading 600ms ease #{300 * $i}ms alternate infinite;
	}
}
@keyframes loading {
	to {
		background-color: rgba(#5877CA, 0.1);
	}
}

@media only screen and (max-aspect-ratio: 1/1) {
	.Loading .Loading-panel {
		width: 100%;
	}
}