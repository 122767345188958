@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.EndGame {
	position: absolute;
	height: 100%;
	width: 100%;
	@include flex('space-between', 'center', 'column');
	padding-bottom: 1em;
	padding-top: 3.6em;

	.EndGame-textWrapper {
		position: absolute;
		z-index: 1;
		bottom: 24em;
		@include flex('', 'center', 'column');

		.EndGame-text1 {
			color: $textDark;
			width: 16.5em;
			padding-bottom: 6.5em;
			p {
				font-weight: 500;
			}
			a {
				color: #1F2850;
				text-decoration: none;
			}
		}
	}
	.EndGame-grill {
		position: absolute;
		z-index: 1;
		background-image: url(../../../assets/images/endGame-smoke.svg);
		bottom: 13em;
		left: calc(50% - 13em);
		width: 28.5em;
		height: 33.73em;
	}

	.EndGame-manager {
		position: absolute;
		pointer-events: none;
		z-index: 3;
		bottom: -12.3em;
		left: calc(50% - 17.25em);
		overflow: hidden;
		&.manager {
			left: calc(50% - 52em);
			height: 27.5em;
		}
	}

	.EndGame-header {
		z-index: 2;
		background-image: url(../../../assets/images/grill-title.svg);
		width: 13.2em;
		height: 6.6em;
	}

	.EndGame-homeBtn {
		position: absolute;
		width: 4.5em;
		height: 4.5em;
		top: 0;
		left: 0;
		background-size: 3.5em auto;
		background-position: center center;
		pointer-events: all;
		cursor: pointer;
		background-image: url('../../../assets/images/icons/icon-home.svg');
	}

	&.portrait {
		.EndGame-homeBtn {
			width: 2.5em;
			height: 2.5em;
			background-size: 2.5em auto;
			top: 0.65em;
			left: 0.5em;
		}
	}

	.EndGame-retryBtn {
		position: absolute;
		z-index: 9;
		width: 10.7em;
		bottom: 11.3em;
	}

	.EndGame-rafflePopupWrapper {
		position: absolute;
		top: 0;
		z-index: 5;
		width: 100%;
		height: 100%;
		background-color: rgba(#1F2850, 0.5);
		@include flex('center', 'center');

		.EndGame-raffleWindow {
			position: relative;
			width: 25em;
			max-width: calc(100% - 2em);
			padding: 1em;
			padding-bottom: 2em;
			border-radius: 0.5em;
			@include box-shadow(0.2em, 0.2em, 0.3em, 0.1em, rgba(black, 0.33));
			background-color: white;
		
			.EndGame-raffleHeader {
				margin-bottom: 1em;
				color: #1F2850;
				span {
					font-size: 1.25em;
					font-weight: bold;
				}
			}
			.EndGame-form {
				@include flex('center', 'center', 'column');
				input {
					display: block;
					font-size: 1.11em;
					line-height: 1;
					width: 100%;
					height: 2.7em;
					border-radius: 0.3em;
					margin: auto;
					margin-bottom: 1em;
					background-color: $white;
					color: #275150;
					font-weight: normal;
					text-align: center;
					@include box-shadow-inset(0, 0.17em, 0.5em, 0, rgba(#C6C6C6, 1));
					&::placeholder {
						color: rgba(#275150, 0.25);
					}
				}
				.EndGame-submitBtn {
					width: 10em;
				}
				.EndGame-checkboxWrapper {
					gap: 1em;
					width: 100%;
					margin-bottom: 0.5em;
					@include flex('flex-end', 'center');

					.EndGame-checkboxText {
						padding-bottom: 0.2em;
					}
					.EndGame-newsletterCheckbox {
						width: 1.5em;
						height: 1.5em;
						border-radius: 0.25em;
						@include box-shadow-inset(0, 0.17em, 0.5em, 0, rgba(#C6C6C6, 1));

						&.checked {
							background-image: url(../../../assets/images/icons/icon-cross.svg);
							background-size: 75% 75%;
						}
					}
				}
				.EndGame-errorMessage {
					position: absolute;
					bottom: 0.25em;
					span {
						color: red;
					}
				}
			}
		}
	}
}