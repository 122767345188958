@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.Survey {
	position: relative;
	height: 100%;
	padding: 7.5em 0 0.5em 0;
	overflow: hidden;
	@include flex('center', 'flex-start');
	.Survey-content {
		width: 55.56em;
		overflow: auto;
		@include hide-scrollbar();
		.Survey-intro {
			width: 100%;
			margin: 0 auto;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			margin-bottom: 1em;
			@include box-shadow(0, 0.17em, 0.33em, 0, rgba(black, 0.2));
		}

		.Survey-options {
			position: relative;
			width: 100%;
			.Survey-optionsWrap {
				position: relative;			
				.Survey-option {
					position: relative;
					width: 100%;
					height: 100%;
					text-align: center;
					padding: 0.75em 1.25em 0.5em 1.25em;
					cursor: pointer;
					@include flex('center', 'center');
					@include no-select();
					&.animate {@include bounce();}
					&.disabled, &.completed {cursor: not-allowed;}
					span:first-of-type {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
					span:last-of-type {
						font-size: 1.3em;
						font-weight: bold;
					}
					.Survey-audio {
						position: absolute;
						right: 0.5em;
						top: calc(50% - 1em);
					}
				}
			}	
		}		
	}

	&:not(.images) .Survey-content {
		.Survey-options {
			padding: 1.11em 0;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			.Survey-optionsWrap {
				width: 22.33em;
				margin: 0 auto;
				.Survey-option {
					min-height: 3em;
					margin-bottom: 1.25em;
					@include button-blue();
					&:last-of-type {
						margin-bottom: 0;
					}
					&.selected {
						@include button-blue-dark();
					}
				}
			}
		}
	}

	&.images .Survey-content {
		.Survey-options {
			padding: 0;
			.Survey-optionsWrap {
				.Survey-option { 
					min-height: auto;
					background: none;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					margin: 0;
					padding: 0;
					border: 0.44em solid transparent;
					@include box-shadow(0, 0, 0, 0, transparent);
					&::after {
						content: '';
						display: none;
						position: absolute;
						top: 0.25em;
						right: 0.25em;
						width: 2.22em;
						height: 2.22em;
						background-size: contain;
					}
					&.selected {
						@include box-shadow(0, 0, 0, 0, transparent);
						&::after {
							display: block;
							background-image: url('../../../../../assets/images/icons/icon-correct.svg');
						}
					}
					&.completed {
						cursor: not-allowed;
					}
				}
			}
		}
	}
}