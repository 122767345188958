@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Background {
	position: absolute;
	width: 100%;
	height: auto;
	min-height: 100%;
	pointer-events: none;
	overflow: hidden;
	@include flex('center', 'flex-start');

	&.outside {
		background-color: $blueSky;

		.Background-svgWrap {
			background-image: linear-gradient(to top, #D1EBEB 5%, #E3F2F2 5%, #F7FBFB 5%, #FFFFFF 5%, $blueSky);
		}
	}

	.Background-svgWrap {
		position: absolute;
		height: auto;
		width: 100%;
		bottom: -4em;
		&.transitionOut {
			@include scale(1.2);
		}
		&.top {
			@include translate(0, 50%);
		}
		&.bottom {
			@include transitionTransform(4s, ease-in-out, 0s);
		}
		.Background-svgComponent {
			height: auto;
			width: 100%;
			
			&.portrait {
				width: auto;
				height: 100%;
				@include translate(-40%, 0);
			}

			// HANDLING CLOUD ANIMATIONS
			#cloud-6-group {
				@include glideAcross(34s, -10s);
			}
			#cloud-5-group {
				@include glideAcross(29s, 2s);
			}
			#cloud-4-group {
				@include glideAcross(35s, 0s);
			}
			#cloud-3-group {
				@include glideAcross(40s, -26s);
			}
			#cloud-2-group {
				@include glideAcross(45s, 10s);
			}
			#cloud-1-group {
				@include glideAcross(37s, -15s);
			}
		}
	}
	
	.Background-imageWrap {
		position: absolute;
		height: 100%;
		width: 100%;
		.Background-image1,
		.Background-image2,

		&.show,
		&.pan,
		&.zoom {
			@include transitionIn();
		}
		&.pan {
			.Background-image1,
			.Background-image2,
			.Background-lockers  {
				@include transition('transform', 2s, 'ease-in-out');
			}
		}
		&.zoom {
			.Background-image1,
			.Background-image2,
			.Background-lockers  {
				@include transition('transform', 1s, 'ease-in-out');
			}
		}
		&.fade {
			@include transitionOut();
		}

		.Background-image1,
		.Background-image2 {
			position: absolute;
			top: 0;
			width: calc(100% + 120em);
			height: 100%;
		}

		&.no-grill {
			.Background-image1 {
				background-image: none;
			}
		}

		&.grill-off {
			.Background-image1 {
				background-image: url('../../../assets/images/backgrounds/outside/grill-off.svg');
				background-size: 11.6em auto;
				background-position: bottom -6em left calc(50% - 53em);
			}
		}

		&.grill-off-center {
			.Background-image1 {
				background-image: url('../../../assets/images/backgrounds/outside/grill-off.svg');
				background-size: 11.6em auto;
				background-position: bottom -6em left calc(50% - 60em);
			}
		}

		&.grill-blazing {
			.Background-image1 {
				background-image: url('../../../assets/images/backgrounds/outside/grill-blazing.svg');
				background-size: 11.6em auto;
				background-position: bottom -6em left calc(50% - 53em);
			}
		}

		&.grill-fired {
			.Background-image1 {
				background-image: url('../../../assets/images/backgrounds/outside/grill-fired.svg');
				background-size: 11.6em auto;
				background-position: bottom -6em left calc(50% - 60em);
			}
		}

		&.grill-on-center {
			.Background-image1 {
				background-image: url('../../../assets/images/backgrounds/outside/grill-fired.svg');
				background-size: 11.6em auto;
				background-position: bottom -6em left calc(50% - 60em);
			}
		}

		&.grill-on {
			.Background-image1 {
				background-image: url('../../../assets/images/backgrounds/outside/grill-on.svg');
				background-size: 11.6em auto;
				background-position: bottom -6em left calc(50% - 53em);
			}
		}

		&.facilitator,
		&.white {
			background-color: $white;
			.Background-image1,
			.Background-image2,
			.Background-lockers {
				display: none;
			}
		}
	}

	.Background-fadeOverlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $black;
		z-index: 2;
		@include opacity(0);
		
		&.transitionIn {
			@include fadeOut(1s);
		}

		&.transitionOut {
			@include fadeIn(1s);
		}
	}
}