@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrganizeDndContainer {
	position: relative;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	&.all {height: auto;}
	
	&.s1-m2-grilling-amount {
		&.initial.empty {
			margin: 0.5em 0.5em;
			width: calc(100% - 1em);
		}
	}
}