@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrderPushDndPreview {	
	.OrderPushDndPreview-item {
		position: relative;
		width: calc(20em - 3em);
		height: $orderItemHeight;
		line-height: 1.25;
		border-radius: 0.55em;
		text-align: center;
		padding: 0.75em 3em 0.5em 3em;
		cursor: pointer;
		transform-origin: left 0.5em center;
		@include flex('center', 'center');
		@include no-select();
		@include button-blue-dark();

		&.right {
			@include rotate(-3deg);
		}

		&.left {
			@include rotate(3deg);
		}

		span {
			color: $white;
			font-size: 1.07em;
			font-weight: bold;
		}

		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 3em;
			left: 0;
			top: 0;
			background-image: url('../../../../../assets/images/icons/icon-drag.svg');
			background-position: right 0.4em center;
			background-size: auto 1.8em;
			background-repeat: no-repeat;
		}
	}
}