@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.SpeechBubble {
	position: absolute;
	left: calc(50% - 51em);
	bottom: 30.5em;
	width: 22.7em;
	background-color: white;
	border-radius: 1.1em;
	padding: 1em;
	padding-right: 2em;
	padding-left: 1.25em;
	text-align: left;
	@include origin(50%, 50%);
	@include fadeInSpeechBubble();
	@include box-shadow(0.25em, 0.25em, 0.5em, 0, rgba(black, 0.3));

	&.instructions {
		left: calc(50% - 12em);
		bottom: 28.5em;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: -1.9em;
		left: 4em;
		width: 0em;
		height: 0em;
		border-top: 2em solid white;
		border-left: 1em solid transparent;
		border-right: 1em solid transparent;
	}

	&.fade {
		@include fadeOutSpeechBubble();
	}

	&.feedback {
		padding: 1.25em 1.5em;
		bottom: 17.5em;
		left: 4em;
	}

	&.portrait {
		left: calc(50% - 22em / 2);
		width: 22em;
		bottom: 32em;

		&.feedback {
			bottom: 10.5em;
			left: 2em;
		}
	}
}