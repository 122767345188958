@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.TrainingGamesBtn {
	z-index: 4;
	background-image: url(../../../assets/images/logo-cgl.svg);
	width: 9.5em;
	height: 1.9em;
	cursor: pointer;
	color: transparent;
}