/* NO ANIMATION */
@mixin noAnimation() {
  -webkit-animation: none;
  animation: none;
}

/* BOUNCE */
@mixin bounce($duration: 0.5s, $delay: 0s, $repeat: 1) {
  -webkit-animation: bounce $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: bounce $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
}
@keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
}

/* WOBBLE */
@mixin wobble($duration: 0.5s, $delay: 0s, $repeat: 1) {
  -webkit-animation: wobble $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: wobble $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);}
  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);}
  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);}
  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);}
  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);}
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
}
@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);}
  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);}
  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);}
  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);}
  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);}
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);}
}

/* SHAKE */
@mixin shake($duration: 0.1s, $delay: 0s, $repeat: 10) {
  -webkit-animation: shake $duration ease-in-out $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: shake $duration ease-in-out $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  100% {@include translate(0, 0);}
}
@keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  0%, 100% {@include translate(0, 0);}
}

/* FADE IN / OUT */
@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}


/* DELAY NEXT BUTTON */
@mixin delayButton($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  @include opacity(0);
  pointer-events: none;
  cursor: default;
  animation: delayButton $seconds #{$timing} $delay forwards;
  -webkit-animation: delayButton $seconds #{$timing} $delay forwards;
}

@keyframes delayButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;}
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;}
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;}
}
@-webkit-keyframes delayButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;}
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;}
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;}
}

// STAR BOUNCE
@mixin star($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  animation: star $seconds #{$timing} $delay forwards;
  -webkit-animation: star $seconds #{$timing} $delay forwards;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 4em;
  height: 4em;

  @keyframes star {
    0% {
      background-size: 50%, 50%;
    }
    50% {
      background-size: 100%, 100%;
    }
    100% {
      background-size: 3.5em, 3.5em;
    }
  }
  @-webkit-keyframes star {
    0% {
      background-size: 50%, 50%;
    }
    50% {
      background-size: 100%, 100%;
    }
    100% {
      background-size: 3.5em, 3.5em;
    }
  }
}
@mixin starPortrait($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  animation: starPortrait $seconds #{$timing} $delay forwards;
  -webkit-animation: starPortrait $seconds #{$timing} $delay forwards;
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: 1.75em;
  height: 1.75em;

  @keyframes starPortrait {
    0% {
      background-size: 50%, 50%;
    }
    50% {
      background-size: 100%, 100%;
    }
    100% {
      background-size: 1.75em, 1.75em;
    }
  }
  @-webkit-keyframes starPortrait {
    0% {
      background-size: 50%, 50%;
    }
    50% {
      background-size: 100%, 100%;
    }
    100% {
      background-size: 1.75em, 1.75em;
    }
  }
}

// STAR GLOW
@mixin starGlow($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  animation: starGlow $seconds #{$timing} $delay forwards;
  -webkit-animation: starGlow $seconds #{$timing} $delay forwards;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 4em;
  height: 4em;
  @include opacity(0);

  @keyframes starGlow {
    0% {
      background-size: 50%, 50%;
      @include opacity(0);
    }
    50% {
      background-size: 100%, 100%;
      @include opacity(1);
    }
    100% {
      background-size: 3em, 3em;
      @include opacity(0);
    }
  }
  @-webkit-keyframes starGlow {
    0% {
      background-size: 50%, 50%;
      @include opacity(0);
    }
    50% {
      background-size: 100%, 100%;
      @include opacity(1);
    }
    100% {
      background-size: 3em, 3em;
      @include opacity(0);
    }
  }
}
@mixin starGlowPortrait($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  animation: starGlowPortrait $seconds #{$timing} $delay forwards;
  -webkit-animation: starGlowPortrait $seconds #{$timing} $delay forwards;
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: 1.75em;
  height: 1.75em;
  @include opacity(0);

  @keyframes starGlowPortrait {
    0% {
      background-size: 50%, 50%;
      @include opacity(0);
    }
    50% {
      background-size: 100%, 100%;
      @include opacity(1);
    }
    100% {
      background-size: 1.75em, 1.75em;
      @include opacity(0);
    }
  }
  @-webkit-keyframes starGlowPortrait {
    0% {
      background-size: 50%, 50%;
      @include opacity(0);
    }
    50% {
      background-size: 100%, 100%;
      @include opacity(1);
    }
    100% {
      background-size: 1.75em, 1.75em;
      @include opacity(0);
    }
  }
}

// SPEECH BUBBLE
@mixin fadeOutSpeechBubble($seconds: 0.5s, $timing: 'ease-in-out', $delay: 0s) {
  animation: fadeOut $seconds #{$timing} $delay forwards;
  -webkit-animation: fadeOut $seconds #{$timing} $delay forwards;
}

@mixin fadeInSpeechBubble($seconds: 0.5s, $timing: 'ease-in-out', $delay: 0s) {
  animation: fadeInSpeechBubble $seconds #{$timing} $delay forwards;
  -webkit-animation: fadeInSpeechBubble $seconds #{$timing} $delay forwards;
  @include opacity(0);

  @keyframes fadeInSpeechBubble {
    0% {
      @include opacity(0);
      @include scale(0);
    }
    50% {
      @include opacity(1);
      @include scale(1.1);
    }
    75% {
      @include scale(0.9);
    }
    100% {
      @include scale(1);
      @include opacity(1);
    }
  }
  @-webkit-keyframes fadeInSpeechBubble {
    0% {
      @include opacity(0);
      @include scale(0);
    }
    50% {
      @include opacity(1);
      @include scale(1.1);
    }
    75% {
      @include scale(0.9);
    }
    100% {
      @include scale(1);
      @include opacity(1);
    }
  }
}

// POPIN
@mixin popIn($duration: 0.5s) {
  -webkit-animation: popIn $duration ease-in 0s forwards;
  animation: popIn $duration ease-in 0s forwards;
  @include scale(0);
  
  @-webkit-keyframes popIn {
    0% {
      @include scale(0);
    }
    40% {
      @include scale(1);
    }
    70% {
      @include scale(0.8);
    }
    100% {
      @include scale(1);
    }
  }
  @keyframes popIn {
    0% {
      @include scale(0);
    }
    40% {
      @include scale(1);
    }
    70% {
      @include scale(0.8);
    }
    100% {
      @include scale(1);
    }
  }
}

// POPIN IMAGE
@mixin popInImage($duration: 0.5s) {
  -webkit-animation: popInImage $duration ease-in 0s forwards;
  animation: popInImage $duration ease-in 0s forwards;  

  @-webkit-keyframes popInImage {
    0% {@include scale(0);}
    40% {@include scale(1);}
    70% {@include scale(0.8);}
    100% {@include scale(1);}
  }
  @keyframes popInImage {
    0% {@include scale(0);}
    40% {@include scale(1);}
    70% {@include scale(0.8);}
    100% {@include scale(1);}
  }
}

// TRANSLATE PERCENTAGE
@mixin translatePercentage($duration: 6s, $delay: 0s, $percentage: 100%) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
    }
    100% {
      @include translate($percentage);
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
    }
    100% {
      @include translate($percentage);
    }
  }

  -webkit-animation: $animation-name $duration linear $delay infinite;
  animation: $animation-name $duration linear $delay infinite;
}

@mixin crateMoveToX($duration: 1s, $delay: 0s, $x) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      @include translate(0, 0);
    }
    60% {
      @include translate(0, 0);
    }
    70% {
      @include translate($x);
    }
    71% {
      @include translate($x);
    }
    100% {
      @include translate($x);
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
      @include translate(0, 0);
    }
    60% {
      @include translate(0, 0);
    }
    70% {
      @include translate($x);
    }
    71% {
      @include translate($x);
    }
    100% {
      @include translate($x);
    }
  }

  -webkit-animation: $animation-name $duration linear $delay infinite;
  animation: $animation-name $duration linear $delay infinite;
}

@mixin crateMoveToY($duration: 1s, $delay: 0s, $y) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      @include opacity(1);
      @include translate(0, 0);
    }
    60% {
      @include translate(0, 0);
    }
    70% {
      @include translate(0, $y);
      @include opacity(1);
    }
    71% {
      @include translate(0, $y);
      @include opacity(0);
    }
    100% {
      @include translate(0, $y);
      @include opacity(0);
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
      @include opacity(1);
      @include translate(0, 0);
    }
    60% {
      @include translate(0, 0);
    }
    70% {
      @include translate(0, $y);
      @include opacity(1);
    }
    71% {
      @include translate(0, $y);
      @include opacity(0);
    }
    100% {
      @include translate(0, $y);
      @include opacity(0);
    }
  }

  -webkit-animation: $animation-name $duration cubic-bezier(0, 0, 0, 1) $delay infinite;
  animation: $animation-name $duration cubic-bezier(0, 0, 0, 1) $delay infinite;
}

@mixin truckDriving($duration, $delay, $from, $midPoint, $to) {
	-webkit-animation: truckDriving $duration ease-in $delay infinite;
	animation: truckDriving $duration ease-in $delay infinite;
  @include opacity(0);

	@-webkit-keyframes truckDriving {
		0% {
      @include translate($from);
      @include opacity(1);
    }
    25% {
      @include translate($from);
    }
    46% {@include translate($midPoint)}
		79% {@include translate($midPoint)}
		100% {
      @include translate($to);
      @include opacity(1);
    }
	}

	@keyframes truckDriving {
		0% {
      @include translate($from);
      @include opacity(1);
    }
    25% {
      @include translate($from);
    }
    46% {@include translate($midPoint)}
		79% {@include translate($midPoint)}
		100% {
      @include translate($to);
      @include opacity(1);
    }
	}
}

@mixin birdFlying($duration: 1s, $delay: 0s) {
  -webkit-animation: birdFlying $duration ease-in $delay forwards;
  animation: birdFlying $duration ease-in $delay forwards;

  @-webkit-keyframes birdFlying {
    0% {
      left: 110%;
    }
    100% {
      left: -10%;
    }
  }
  @keyframes birdFlying {
    0% {
      left: 110%;
    }
    100% {
      left: -10%;
    }
  }
}

@mixin birdFraction($duration: 1s, $delay: 0s) {
  -webkit-animation: birdFraction $duration ease-in $delay infinite;
  animation: birdFraction $duration ease-in $delay infinite;

  @-webkit-keyframes birdFraction {
    0% {
      @include opacity(1);
    }
    10% {
      @include opacity(1);
    }
    11% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
  @keyframes birdFraction {
    0% {
      @include opacity(1);
    }
    10% {
      @include opacity(1);
    }
    11% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
}

@mixin slideInFromLeft($duration: 1s, $delay: 0s) {
  animation: slideInFromLeft $duration ease-in-out $delay forwards;
  -webkit-animation: slideInFromLeft $duration ease-in-out $delay forwards;

  @keyframes slideInFromLeft {
    from {@include translate(-100vw);}
    to {@include translate(0);}
  }
  @-webkit-keyframes slideInFromLeft {
    from {@include translate(-100vw);}
    to {@include translate(0);}
  }
}

@mixin glideAcross($duration: 1s, $delay: 0s) {
  -webkit-animation: glideAcross $duration ease-in $delay infinite;
  animation: glideAcross $duration ease-in $delay infinite;
  @include opacity(0);

  @-webkit-keyframes glideAcross {
    0% {
      @include translate(-30%);
      @include opacity(1);
    }
    100% {
      @include translate(130%);
      @include opacity(1);
    }
  }
  @keyframes glideAcross {
    0% {
      @include translate(-30%);
      @include opacity(1);
    }
    100% {
      @include translate(130%);
      @include opacity(1);
    }
  }
}

@mixin driveForthThenBack($duration: 1s, $delay: 0s, $fromX, $midX, $toX) {
  -webkit-animation: driveForthThenBack $duration ease-in-out $delay infinite;
  animation: driveForthThenBack $duration ease-in-out $delay infinite;

  @-webkit-keyframes driveForthThenBack {
    0% {
      @include translate($fromX, 0);
    }
    48% {
      @include translate($fromX, 0);
    }
    55% {
      @include translate($midX, 0);
    }
    60% {
      @include translate($midX, 0);
    }
    65% {
      @include translate($toX, 0);
    }
    70% {
      @include translate($toX, 0);
    }
    77% {
      @include translate($fromX, 0);
    }
    100% {
      @include translate($fromX, 0);
    }
  }
  @keyframes driveForthThenBack {
    0% {
      @include translate($fromX, 0);
    }
    48% {
      @include translate($fromX, 0);
    }
    55% {
      @include translate($midX, 0);
    }
    60% {
      @include translate($midX, 0);
    }
    65% {
      @include translate($toX, 0);
    }
    70% {
      @include translate($toX, 0);
    }
    77% {
      @include translate($fromX, 0);
    }
    100% {
      @include translate($fromX, 0);
    }
  }
}

@mixin goUpAndDown($duration: 1s, $delay: 0s, $fromY, $middleY, $toY) {
  -webkit-animation: goUpAndDown $duration ease-in-out $delay infinite;
  animation: goUpAndDown $duration ease-in-out $delay infinite;

  @-webkit-keyframes goUpAndDown {
    0% {
      @include translate(0, $fromY);
    }
    55% {
      @include translate(0, $fromY);
    }
    60% {
      @include translate(0, $toY);
    }
    65% {
      @include translate(0, $toY);
    }
    70% {
      @include translate(0, $middleY);
    }
    80% {
      @include translate(0, $middleY);
    }
    85% {
      @include translate(0, $fromY);
    }
    100% {
      @include translate(0, $fromY);
    }
  }
  @keyframes goUpAndDown {
    0% {
      @include translate(0, $fromY);
    }
    55% {
      @include translate(0, $fromY);
    }
    60% {
      @include translate(0, $toY);
    }
    65% {
      @include translate(0, $toY);
    }
    70% {
      @include translate(0, $middleY);
    }
    80% {
      @include translate(0, $middleY);
    }
    85% {
      @include translate(0, $fromY);
    }
    100% {
      @include translate(0, $fromY);
    }
  }
}

@mixin leaveCrate($duration: 1s, $delay: 0s) {
  -webkit-animation: leaveCrate $duration ease-in-out $delay infinite;
  animation: leaveCrate $duration ease-in-out $delay infinite;

  @-webkit-keyframes leaveCrate {
    0% {
      @include opacity(0);
    }
    20% {
      @include opacity(1);
    }
    65% {
      @include opacity(1);
    }
    70% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
  @keyframes leaveCrate {
    0% {
      @include opacity(0);
    }
    20% {
      @include opacity(1);
    }
    65% {
      @include opacity(1);
    }
    70% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
}

@mixin panDown($duration: 1s, $delay: 0s) {
  -webkit-animation: panDown $duration ease-in-out $delay forwards;
  animation: panDown $duration ease-in-out $delay forwards;

  @-webkit-keyframes panDown {
    100% {
      @include translate(0, 0%);
    }
  }
  @keyframes panDown {
    100% {
      @include translate(0, 0%);
    }
  }
}