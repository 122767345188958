@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.LoginPlayer {
	
	.LoginPlayer-form {
		position: relative;
		width: 19.33em;
	
		input {
			display: block;
			font-size: 1.11em;
			line-height: 1;
			width: 100%;
			height: 2.7em;
			border-radius: 0.3em;
			margin: auto;
			margin-bottom: 1em;
			background-color: $white;
			color: #275150;
			font-weight: normal;
			text-align: center;
			@include box-shadow-inset(0, 0.17em, 0.5em, 0, rgba(#C6C6C6, 1));
			&::placeholder {
				color: rgba(#275150, 0.25);
			}
		}
		.LoginPlayer-loginBtn {
			width: 100%;
		}
		
		.LoginPlayer-errorMessage {
			height: 3em;
			margin-top: 1em;
			color: #6A7992;
			text-align: center;
			span {
				font-size: 0.85em;
			}
		}
	}	

	.LoginPlayer-popup {
		position: absolute;
		left: calc(50% - 25.5em / 2);
		top: calc(50% - 10.67em / 2 + 2em);
		width: 25.5em;
		height: 10.67em;
	}
}