@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.Match {
	position: relative;
	height: 100%;
	padding: 7.5em 0 0.5em 0;
	overflow: hidden;
	@include flex('flex-start', 'center', 'column');

	.Match-content {
		position: relative;;
		.Match-intro {
			width: 55.56em;
			margin: 0 auto;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			margin-bottom: 1em;
			@include box-shadow(0, 0.17em, 0.33em, 0, rgba(black, 0.2));
		}
		.Match-categoriesAndItems {
			position: relative;
			width: 48em;
			margin: 2em auto 0 auto;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			padding: 1.7em 3.4em 3em 3.4em;
			@include box-shadow(0, 0.17em, 0.33em, 0, rgba(black, 0.2));
			@include flex('space-between', 'flex-start');

			.Match-categoriesWrap,
			.Match-itemsWrap {
				position: relative;
				width: 50%;
				padding: 0 1em;
				.Match-categoriesTitle,
				.Match-itemsTitle {
					text-align: center;
					margin: 0.5em 0;
					span {
						color: $textDark;
						font-size: 1.33em;
						font-weight: 700;
					}
				}
				.Match-categories,
				.Match-items {
					.Match-category,
					.Match-item {
						position: relative;
						min-height: 4.44em;
						margin-top: 1.22em;
						padding: 0 2.75em 0 2.5em;
						cursor: pointer;
						@include button-blue-dark();
						&.selected {
							@include button-blue();
						}
						&.completed {
							cursor: not-allowed;
							@include button-green();
						}
						&.animateCorrect {
							@include bounce();
						}
						&.animateWrong {
							@include button-red();
							@include wobble();
						}
						span {
							font-size: 1.33em;
							font-weight: bold;
						}

						.Match-audio {
							position: absolute;
							right: 0.5em;
							top: calc(50% - 1em);
						}
					}
				}
			}

		}
	}	
}