@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.Order {
	position: relative;
	height: 100%;
	padding: 7.5em 0 0.5em 0;
	overflow: hidden;
	@include flex('flex-start', 'center', 'column');
	.Order-intro {
		width: 55.56em;
		margin: 0 auto;
		background-color: rgba(white, 0.8);
		border-radius: 1.11em;
		margin-bottom: 1em;
		@include box-shadow(0, 0.17em, 0.33em, 0, rgba(black, 0.2));
	}
	.Order-itemsWrap {
		position: relative;
		width: 55.56em;
		margin: 0 auto;
		padding: 1.7em 7.33em 1.7em 4.8em;
		background-color: rgba(white, 0.8);
		border-radius: 1.11em;
		overflow: auto;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		@include hide-scrollbar();
		@include box-shadow(0, 0.17em, 0.33em, 0, rgba(black, 0.2));
		.Order-items {
			position: relative;
			width: 100%;
			&.size-3 {height: calc(3 * $orderItemHeight + 2 * 1.1em);}
			&.size-4 {height: calc(4 * $orderItemHeight + 3 * 1.1em);}
			&.size-5 {height: calc(5 * $orderItemHeight + 4 * 1.1em);}
			&.size-6 {height: calc(6 * $orderItemHeight + 5 * 1.1em);}
			
			.Order-container {
				position: absolute;
				top: 0;
				height: $orderItemHeight;
				@include flex('flex-start', 'center');
				.Order-containerNumber {
					display: block;
					width: 2.5em;
					height: 100%;
					@include flex('flex-start', 'center');
					span {
						font-size: 1.33em;
						color: $textDark;
						font-weight: bold;
						
					}
				}
				&.pos-2 {top: calc($orderItemHeight + 1.1em);}
				&.pos-3 {top: calc(2 * ($orderItemHeight + 1.1em));}
				&.pos-4 {top: calc(3 * ($orderItemHeight + 1.1em));}
				&.pos-5 {top: calc(4 * ($orderItemHeight + 1.1em));}
				&.final {
					left: 0;
					width: calc(19.33em + 2.5em);
				}
				&.initial {
					left: calc(100% - 19.33em);
					width: 19.33em;
				}
			}
			.Order-item {
				position: absolute;
				top: 0;
				left: 2.5em;
				@include flex('center', 'center');
				@include no-select();
				&.pos-2 {top: calc($orderItemHeight + 1.1em);}
				&.pos-3 {top: calc(2 * ($orderItemHeight + 1.1em));}
				&.pos-4 {top: calc(3 * ($orderItemHeight + 1.1em));}
				&.pos-5 {top: calc(4 * ($orderItemHeight + 1.1em));}
				&.final {
					@include translate(0);
					
				}
				&.initial {
					@include translate(21.55em);
					width: 19.33em;
				}
			
				&.correct {
					background-color: greenyellow;
					cursor: not-allowed;
				}
				&.animate {
					@include transitionTransform(1s, ease-in-out, 0.5s);
				}
			}
		}
	}
	.Order-doneBtn {
		position: absolute;
		bottom: 1.33em;
		left: calc(50% - 0.5 * 8.75em);
		width: 8.75em;
	}
}