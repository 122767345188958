@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.TaskIntro {
	position: relative;
	width: 100%;		
	padding: 1.8em 4.5em 1.8em 2em;
	text-align: left;

	&.portrait {
		padding: 1em 4em 1em 1.5em;
		.TaskIntro-text {
			p {
				font-size: 1.2em;
			}
			span {
				font-size: 1.2em;
			}
		}
	}

	.TaskIntro-text {
		color: $textDark;
		p {
			font-size: 1.33em;
			font-weight: 500;
			margin-top: 0;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		span {
			display: inline-block;
			margin-top: 1em;
			font-size: 1.33em;
			font-weight: bold;
		}
	}

	.TaskIntro-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-size: 5.22em auto;
		background-position: bottom right;
	}

	.TaskIntro-audio {
		position: absolute;
		top: calc(50% - (0.5 * 2.25em));
		right: 0.8em;
	}
}
