@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.SurveyResults {
	position: relative;
	.SurveyResults-question {
		margin-bottom: 1em;
		span {
			font-weight: bold;
		}
		p {
			&:first-of-type {margin-top: 0;}
			&:last-of-type {margin-bottom: 0;}
		}
	}
	.SurveyResults-noResults {
		span {
			font-style: italic;
		}
	}
	.SurveyResults-results {
		.SurveyResults-option {
			padding: 0.5em 1em;
			@include flex('space-between', 'center');
			&:nth-child(odd) {
				background-color: rgba(#f2482b, 0.2);
			}
			.SurveyResults-optionText {
				width: 25em;
			}
		}
	}
}
