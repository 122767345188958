@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Logo {
	display: block;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/logo-tican.svg');
	background-size: contain;

	&.cgl {
		color: transparent;
		overflow: hidden;
		@include no-select();
		background-image: url('../../../assets/images/logo-cgl.svg');
	}
}