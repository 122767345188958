@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Audio {
	width: 2.25em;
	height: 2.25em;
	.rhap_container {
		.rhap_main {
			.rhap_progress-section {
				display: none;
			}
			.rhap_controls-section {
				.rhap_additional-controls,
				.rhap_volume-controls {
					display: none;
				}
				.rhap_main-controls {
					button {
						width: 100%;
						height: 100%;
						padding: 0;
						border-style: none;
						background-color: transparent;
						cursor: pointer;
					}
					svg {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	&.task-option {
		width: 2em;
		height: 2em;
	}
}