@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Star {
	position: relative;
	width: 3.5em;
	height: 3.5em;

	&.portrait {
		width: 1.5em;
		height: 1.5em;

		.Star-glow {
			@include starGlowPortrait(0.5s);
		}

		.Star-image {
			&.animate {
				@include starPortrait(0.5s)
			}
		}
	}

	.Star-glow {
		position: absolute;
		z-index: 3;
		top: 0em;
		width: 100%;
		height: 100%;
		background-image: radial-gradient(transparent 5%, white, transparent 75%);
		@include starGlow(0.5s);
	}
	.Star-image {
		position: relative;
		z-index: 4;
		@include flex('center', 'center');
		width: 100%;
		height: 100%;
		background-image: url('../../../assets/images/icons/icon-star.svg');
		background-size: contain;
		background-position: center center;

		&.filled {
			background-image: url('../../../assets/images/icons/icon-star-filled.svg');
		}
		&.animate {
			@include star(0.5s);
		}

		&.facilitator {
			width: 1.5em;
			height: 1.5em;
			&.filled {
				width: 1.5em;
				height: 1.5em;
			}
		}
	}
}
