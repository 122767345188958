@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrganizeDndItem {
	position: relative;
	display: inline-block;

	.OrganizeDndItem-img {
		width: 100%;
		height: 100%;
		position: relative;
	}

	&.s1-m2-grilling-amount {
		width: 7.5em;
		height: 5em;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-size: 3.85em auto;
			background-position: 0.65em 0.725em;
			background-repeat: no-repeat;
		}
		.OrganizeDndItem-img {
			height: 5em;
			width: 7.5em;

			&.chicken {
				&.well-done {
					background-size: 5.3em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/chicken-welldone.svg');
				}
				&.medium {
					background-size: 5.3em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/chicken-medium.svg');
				}
				&.ungrilled {
					background-size: 5.3em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/chicken-raw.svg');
				}
				&.initial {
					background-size: 5.3em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/chicken-raw.svg');
				}
			}
			&.sausage {
				&.well-done {
					background-size: 6.6em 3.6em;
					background-position: bottom 0.5em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/sausage-welldone.svg');
				}
				&.medium {
					background-size: 6.6em 3.6em;
					background-position: bottom 0.5em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/sausage-medium.svg');
				}
				&.ungrilled {
					background-size: 6.6em 3.6em;
					background-position: bottom 0.5em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/sausage-raw.svg');
				}
				&.initial {
					background-size: 6.8em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/sausage-raw.svg');
				}
			} 
			&.mince {
				&.well-done {
					background-size: 5.1em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/mince-welldone.svg');
				}
				&.medium {
					background-size: 5.1em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/mince-medium.svg');
				}
				&.ungrilled {
					background-size: 5.1em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/mince-raw.svg');
				}
				&.initial {
					background-size: 5.1em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/mince-raw.svg');
				}
			} 
			&.beef {
				&.well-done {
					background-size: 4.5em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/beef-welldone.svg');
				}
				&.medium {
					background-size: 4.5em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/beef-medium.svg');
				}
				&.ungrilled {
					background-size: 4.5em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/beef-raw.svg');
				}
				&.initial {
					background-size: 4.5em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/beef-raw.svg');
				}
			} 
			&.squash {
				&.well-done {
					background-size: 7.2em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/squash-welldone.svg');
				}
				&.medium {
					background-size: 7.2em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/squash-medium.svg');
				}
				&.ungrilled {
					background-size: 7.2em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/squash-raw.svg');
				}
				&.initial {
					background-size: 7.2em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/squash-raw.svg');
				}
			} 
			&.carton {
				&.well-done {
					background-size: 5.8em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/carton-welldone.svg');
				}
				&.medium {
					background-size: 5.8em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/carton-medium.svg');
				}
				&.ungrilled {
					background-size: 5.8em auto;
					background-position: bottom 1em right calc(50%);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/carton-raw.svg');
				}
				&.initial {
					background-size: 5.8em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/carton-raw.svg');
				}
			} 
		}
	}
}