@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.SortDndItem {
	position: relative;
	width: 20em;
	height: 3em;
	padding: 0 2.75em 0 2.5em;
	margin-bottom: 0.5em;
	cursor: move;
	@include button-blue-dark();
	@include flex('flex-start', 'center');
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 2.5em;
		height: 100%;
		background-image: url('../../../../../assets/images/icons/icon-drag.svg');
		background-size: auto 1.33em;
		background-position: center center;
	}
	span {
		color: white;
		font-size: 1.1em;
		font-weight: 700;
		@include no-select();
	}

	&.disabled {
		cursor: not-allowed;
		background-image: none;
	}

	&.completed,
	&.animateCorrect {
		@include button-green();
		@include flex('flex-start', 'center');
		&:after {
			background-image: none;
		}
		cursor: not-allowed;
	}

	&.animateCorrect {
		@include bounce();
	}
	&.animateWrong {
		@include wobble();
		@include button-red();
		@include flex('flex-start', 'center');
	}
}