@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/buttons';
@import 'styles/animations';
html {
	position: relative;
	height: 100%;
}

body {
	position: relative;
	height: 100%;
	width:100%;
	margin: 0;
	padding: 0;
	font-size: calc(18 * ((100vw / 1920))); // desktop first layout
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	overflow: hidden;
	color: black;
	background-color: white;
}

#root {
	height: 100%;
	overflow: hidden;
}

*,
*::after,
*::before {
	box-sizing: border-box;
  	font-family: 'Roboto', sans-serif;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

button {
	background-image: none;
	-webkit-tap-highlight-color: transparent;
	@include no-select();
	&:focus {
		outline: 0;
	}
	&::-moz-focus-inner {
		border: 0;
	}
}

ul, ol {
	padding-inline-start: 1em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
	font-size: inherit;
	border-style: none;
	outline: none;
	box-shadow: none;
	@include appearance(none);
}

strong {
	font-weight: bold;
}

@media (min-aspect-ratio: 1920/1080) {
	body {
		font-size: calc(18 * ((100vh / 1080)));
	}
}

@media (orientation: portrait) {
	body {
		font-size: calc(18 * ((100vw / 430)));
	}
}

@media (min-aspect-ratio: 19/40) and (orientation: portrait) {
	body {
		font-size: calc(14 * ((100vw / 430)));
	}
}

@media (min-aspect-ratio: 3/4) {
	body {
		font-size: calc(22 * ((100vh / 1024)))
	}

	@media (orientation: portrait) {
		body {
			font-size: calc(22 * ((100vw / 667)))
		}
	}
}

@media (min-aspect-ratio: 2/3) {
	body {
		font-size: calc(22 * ((100vh / 1366)))
	}

	@media (orientation: portrait) {
		body {
			font-size: calc(24 * ((100vw / 1024)))
		}
	}
}