@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Character {
	&.flipped {
		@include scaleX(-1);
	}

	&.seagull {
		width: 13.2em;
		height: 13.2em;
		background-image: url(../../../assets/images/characters/seagull.svg);
		background-size: 16em auto;
		background-position: top 2.25em left -3.5em;
	}

	&.portrait {
		&.seagull {
			width: 7.8em;
			height: 7.8em;
			background-image: url(../../../assets/images/characters/seagull.svg);
			background-size: 8.14em auto;
			background-position: top 1.25em left -1.5em;
		}
	}
}