@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.FacilitatorPlayerPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');

	.FacilitatorPlayerPopup-content {
		width: 80em;
		min-height: 14em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorPlayerPopup-header {
			position: relative;
			width: 100%;
			// height: 6em;
			background-color: #f2482b;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			border-bottom-width: 0em;
			color: white;
			font-weight: bold;
			padding: 0.5em;
			@include flex('space-between', 'flex-end');
			.FacilitatorPlayerPopup-playerInfo {
				width: 50%;
				text-align: left;
				.FacilitatorPlayerPopup-playerId {
					width: 100%;
					text-align: left;
					span {
						display: block;
						font-size: 1.25em;
						font-weight: bold;
					}
				}
				.FacilitatorPlayerPopup-lastLogin {
					margin: 0.75em 0 0.65em 0;
					span {
						font-size: 1em;
					}
				}
				.FacilitatorPlayerPopup-status {
					@include flex('flex-start', 'center');
					.FacilitatorPlayerPopup-statusLabel {
						margin-right: 1.5em;
						width: 7em;
					}
					.FacilitatorPlayerPopup-statusBtn {
						width: 5em;
					}
				}
			}
			.FacilitatorPlayerPopup-modules {
				width: 50%;
				@include flex('space-between', 'flex-end', 'column');

				.FacilitatorPlayerPopup-modulesSelectWrapper {
					@include flex('flex-end', 'center');
					.FacilitatorPlayerPopup-modulesLabel {
						font-size: 1.25em;
						margin-right: 1em;
					}
					.FacilitatorPlayerPopup-dropdown {
						width: 20em;
					}
					.FacilitatorPlayerPopup-modulesResetButton {
						margin-left: 1em;
					}
				}
			}
			.FacilitatorPlayerPopup-closeBtn {
				position: absolute;
				top: 0em;
				right: 0em;
				width: 2em;
				height: 2em;
				background-image: url('../../../assets/images/icons/icon-cross-white.svg');
				background-size: 1.25em auto;
				cursor: pointer;
				&:hover {
					background-size: 1.5em auto;
				}
			}
		}

		.FacilitatorPlayerPopup-moduleHeader {
			@include flex('space-between','center','row');
			background-color: white;
			height: 4em;
			.FacilitatorPlayerPopup-moduleTitle {
				@include flex('space-between','center');
				width: 40%;
				height: 100%;
				padding: 0 0.5em;
				p {
					font-weight: bold;
					font-size: 1.5em;
					margin: 0;
				}
				.FacilitatorPlayerPopup-moduleStars {
					position: relative;
					@include flex('space-between','center');
				}
			}
			.FacilitatorPlayerPopup-progress,
			.FacilitatorPlayerPopup-timeSpent,
			.FacilitatorPlayerPopup-mistakes {
				border-left: 1px solid #f2482b;
				width: 20%;
				height: 100%;
				@include flex('center', 'center', 'column');
				span {
					font-weight: bold;
					&:first-of-type {
						color: black;
					}
					&.last-of-type {
						color: #707070;
					}
				}
			}

		}

		.FacilitatorPlayerPopup-tasksHeader {
			@include flex('space-between', 'center', 'row');
			background-color: #f2482b;
			font-weight: bold;
			color: white;
			padding: 0.5em 2em;
			> div {
				width: 35%;
				text-align: left;
				&:nth-of-type(3),
				&:nth-of-type(4) {
					text-align: right;
					width: 15%;
				}
			}
		}

		.FacilitatorPlayerPopup-tasksBody { 
			overflow: auto;
			max-height: 20em;
			min-height: 15em;
			padding-bottom: 1em;
			@include hide-scrollbar();
			.FacilitatorPlayerPopup-row {
				padding: 0.5em 2em;
				@include flex('space-between', 'center', 'row');
				&:nth-child(odd) {background-color: rgba(#f2482b, 0.2);}
				> div
						{
							width: 35%;
							text-align: left;
							&:nth-of-type(3),
							&:nth-of-type(4) {
								text-align: right;
								width: 15%;
							}
							
						}
					}
		}
	}

	.FacilitatorPlayerPopup-resetPlayerPopup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000000, 0.5);
		@include flex('center', 'center');
		z-index: 5;
		.FacilitatorPlayerPopup-resetPlayerPopupContent {
			position: relative;
			width: 40em;
			background-color: white;
			border-radius: 0.83em;
			padding: 2em 1em;
			
			.FacilitatorPlayerPopup-resetPlayerPopupText {
				margin-bottom: 2em;
				p {
					font-size: 1em;
				}
			}
			.FacilitatorPlayerPopup-resetPlayerPopupButtons {
				@include flex('space-between', 'center');
				> div {
					width: 12em;
				}
			}
		}
	}
}