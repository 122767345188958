@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.Organize {
	position: relative;
	height: 100%;
	padding-top: 3em;
	padding-bottom: 1em;
	overflow: auto;

	&.portrait {
		padding-top: 1.5em;
	}

	.Organize-intro {
		position: relative;
		width: 55.56em;
		max-width: calc(100% - 2em);
		background-color: rgba(white, 0.8);
		border-radius: 0.5em;
		margin: 2em auto 0.75em auto;
	}
	.Organize-mainContainer {
		.Organize-containers {
			position: relative;
			@include flex('flex-start', 'flex-start', 'row');
			flex-wrap: wrap;
		}
		.Organize-scroll {
			position: fixed;
			cursor: pointer;
			z-index: 3;
			width: 3em;
			height: 10.75em;
			bottom: 2.5em;

			@include transition('transform', 0.15);

			&.right {
				background-image: url(../../../../../assets/images/icons/icon-arrow-2.svg);
				background-size: 95% 95%;
				width: 3.33em;
				height: 3.33em;
				right: 0;
				bottom: 1.5em;
				margin: 0.5em;

				&:hover {
					@include scale(1.2);
				}
			}
			&.left {
				background-image: url(../../../../../assets/images/icons/icon-arrow.svg);
				background-size: 95% 95%;
				width: 3.33em;
				height: 3.33em;
				left: 0;
				bottom: 1.5em;
				margin: 0.5em;

				&:hover {
					@include scale(1.2);
				}
			}
		}
	}

	.Organize-doneBtn {
		position: fixed;
		right: calc(50% - 7em / 2);
		bottom: 0.5em;
		z-index: 2;
		width: 7em;
	}

	&.s1-m2-grilling-amount {
		.Organize-mainContainer {
			position: relative;
			.Organize-mainContainerTitle {
				position: absolute;
				bottom: -0.12em;
				left: calc(50% - 12.2em / 2);
				z-index: 1;
				background-color: white;
				width: 12.2em;
				padding: 0.25em;
				border-radius: 0.5em;
				span {
					font-size: 0.9em;
				}
			}
			&.initial {
				position: fixed;
				bottom: 0;
				width: 100%;
				height: 6.5em;
				.Organize-containers {
					display: block;
					width: 100%;
					height: 100%;
					background-color: #444648;
					padding: 0.25em 0.5em;
					overflow: hidden;
					overflow-x: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 7.5em;
						height: 5em;
						margin-right: 0em;

						.Organize-scroll {
							bottom: calc(6.5em - (10.75em / 2));
						}
					}
				}
			}
			&.well-done,
			&.medium, 
			&.ungrilled {
				.Organize-containers {
					width: 22.2em;
					height: 10.2em;
					left: calc(50% - 22.2em / 2);
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/background.svg');
					background-size: 100% auto;
					background-position: bottom center;
					padding-left: 0.75em;
					padding-right: 0.75em;
					padding-bottom: 7.4em;
					margin-bottom: 0.78em;
					@include flex('space-between', 'row');
					flex-wrap: wrap;
					.Organize-container {
						position: absolute;
						background-size: 2.5em auto;
						background-position: bottom center;
						width: 9em;
						height: 5em;
						margin-bottom: 0em;
						border-radius: 100%;

						&.pos-0 {
							left: 7em;
						}
						&.pos-1 {
							top: 3.5em;
						}
						&.pos-2 {
							top: 3.5em;
							right: 0.25em;
						}
					}
				}
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(#444648, 0) 0%, rgba(#444648, 1) 10%, rgba(#444648, 1) 90%, rgba(#444648, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}
}