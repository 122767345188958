@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Popup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#222E5B, 0.5);
	z-index: 13;
	@include flex('center', 'center');

	.Popup-content {
		position: relative;
		width: 33.33em;
		max-width: calc(100% - 2em);
		min-height: 10em;
		border-radius: 1.11em;
		color: $textDark;
		background-color: rgba(white, 0.8);
		padding: 1.5em;
		@include box-shadow(0, 0.167em, 0.33em, 0, rgba(black, 0.2));

		.Popup-title {
			margin-bottom: 0.75em;
			span {
				color: $textDark2;
				font-size: 2.25em;
				font-weight: 900;
			}
		}
		.Popup-text {
			margin-top: 0.5em;
			p {
				font-size: 1.25em;
				font-weight: 500;
			}
		}

		.Popup-buttons {
			@include flex('space-between');
		}
	}

	&.facilitator {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
		.Popup-content {
			width: 40em;
			padding: 2em 1em;
		}

		.Popup-buttons {
			@include flex('space-between', 'center');
			width: 20em;
			> div {
				width: 8em;
				height: 2.67em;
				span {
					font-size: 1.2em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
		}
	}

	&.login {
		.Popup-buttons {
			margin: 0 auto;
			@include flex('space-between', 'center');
			width: 85%;
			> div {
				width: 11.11em;
				height: 3em;
				span {
					font-size: 1.2em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
		}
	}

	&.try-again {
		.Popup-buttons {
			margin: 0 auto;
			width: 85%;
			> div {
				width: 11.11em;
				height: 3em;
			}
		}
	}

	&.spot-errors-result,
	&.order-result {
		.Popup-content {
			min-height: 15em;
			@include flex('center', 'center');
			.Popup-text {
				margin-bottom: 3em;
			}
			.Popup-buttons {
				> div {
					width: 11.11em;
					height: 3em;
				}
			}
		}
		
	}
}
