@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.AdminTextToSpeechPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');

	.AdminTextToSpeechPopup-content {
		width: 40em;
		min-height: 14em;
		background-color: white;
		border-radius: 0.83em;

		.AdminTextToSpeechPopup-header {
			position: relative;
			width: 100%;
			height: 3em;
			background-color: #f2482b;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			border-bottom-width: 0em;
			color: white;
			font-weight: bold;
			padding: 0.5em;
			@include flex('space-between', 'center');
			.AdminTextToSpeechPopup-title {
				span {
					font-size: 1.5em;
					font-weight: bold;
				}
			}
			.AdminTextToSpeechPopup-closeBtn {
				width: 2em;
				height: 2em;
				background-image: url('../../../assets/images/icons/icon-cross-white.svg');
				background-size: 1.25em auto;
				cursor: pointer;
				&:hover {
					background-size: 1.5em auto;
				}
			}
		}

		.AdminTextToSpeechPopup-body {
			padding: 2em 1em;
			text-align: left;

			.AdminTextToSpeechPopup-config {
				height: 8em;
				.AdminTextToSpeechPopup-configTitle {
					margin-bottom: 0.5em;
					span {
						font-size: 1.25em;
						font-weight: bold;
					}
				}	
				.AdminTextToSpeechPopup-configWrap {
					@include flex('flex-start', 'flex-start');
					.AdminTextToSpeechPopup-configLanguage,
					.AdminTextToSpeechPopup-configVoice {
						width: 10em;
						margin-right: 2em;
						> span {
							font-size: 1.1em;
							font-weight: bold;
						}
						> a {
							font-size: 1.1em;
							font-weight: bold;
							margin-left: 1em;
							text-decoration: none;
						}
					}
					.AdminTextToSpeechPopup-configVoice {
						width: 18em;
					}
				}
			}
			.AdminTextToSpeechPopup-modules {
				margin-bottom: 2em;
				.AdminTextToSpeechPopup-modulesTitle {
					margin-bottom: 0.5em;
					span {
						font-size: 1.25em;
						font-weight: bold;
					}
				}
				.AdminTextToSpeechPopup-module {
					position: relative;
					margin-bottom: 1em;
					padding-left: 2em;
					cursor: pointer;
					@include no-select();
					&::after {
						content: '';
						position: absolute;
						width: 1.2em;
						height: 1.2em;
						top: 0.2em;
						left: 0em;
						border: 0.1em solid black;
					}
					&.selected::after {
						background-image: url('../../../assets/images/icons/icon-checkmark.svg');
						background-size: 90% auto;
					}
					.AdminTextToSpeechPopup-moduleTitle {
						span {
							font-size: 1.1em;
							font-weight: bold;
						}
					

					}
				}

			}
			.AdminTextToSpeechPopup-uploadBtn {
				width: 12em;
			}
			.AdminTextToSpeechPopup-feedback {
				height: 2em;
				
			}
		}
	}
}