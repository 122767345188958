@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/animations';
.Facilitator {
	font-size: calc(18 * ((100vw / 1920)));
	.Facilitator-header {
		width: 100%;
		height: 12em;
	}
	.Facilitator-content {
		position: absolute;
		width: 100%;
		top: 12em;
		bottom: 0;
		padding: 1.67em 2em;
		overflow: auto;
		@include hide-scrollbar();
		.Facilitator-players {
			width: 100%;
		}
	}
}