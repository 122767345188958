@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/buttons';
@import '../../../../styles/animations';


.AdminModuleStats {
	position: relative;
	height: calc(100% - 4.25em);
	overflow: auto;
	padding: 0 1.5em;
	text-align: left;
	@include hide-scrollbar();

	.AdminModuleStats-players {
		height: 2em;
		text-align: center;
		margin-bottom: 1em;
		span {
			font-size: 1.25em;
			font-weight: bold;
		}
	}
	.AdminModuleStats-cell {
		width: 18%;
		height: 100%;
		padding: 0 1.5em;
		@include flex('flex-start', 'center');

		&.task {
			width: 25%;
		}
		&.type {
			width: 21%;
		}
	}
		
	.AdminModuleStats-header {
		width: 100%;
		height: 2.33em;
		color: white;
		background-color: #f2482b;
		@include flex('space-between', 'center');
		.AdminModuleStats-cell {
			span {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				font-size: 1.39em;
				font-weight: 600;

			}
		}
	}
	.AdminModuleStats-body {
		width: 100%;
		.AdminModuleStats-row {
			width: 100%;
			height: 2.5em;
			color: #525252;
			@include flex('space-between', 'center');
			&:nth-child(odd) {
				background-color: rgba(#f2482b, 0.2);						
			}
			.AdminModuleStats-cell.remove {
				@include flex('flex-end', 'center');
			}
		}
	}
}