@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Select {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.56em;
	background-color: white;
	color: black;
	font-weight: normal;
	text-align: left;
	cursor: pointer;
	&.open {
		border-radius: 0.56em 0.56em 0 0;
		.Select-dropdown {
			height: auto;
			border-radius: 0 0 0.56em 0.56em;
			border-top: 1px solid black;
		}
	}
	&.disabled {
		cursor: default;
		color: black;
		background-color: rgb(225, 225, 225);
		.Select-button {display: none;}
	}
	
	.Select-panel {
		height: 100%;
		padding: 0.56em 1em;
	}
	
	.Select-selected {
		span {
			line-height: 1;
		}
	}
	.Select-button {
		position: absolute;
		top: 0;
		right: 0;
		width: 2.25em;
		height: 2.25em;
		background-image: url('../../../assets/images/icons/icon-arrow-down.svg');
		background-position: top 0.9em right 0.75em;
		background-size: auto 0.5em;
		background-repeat: no-repeat;
	}

	.Select-dropdown {
		position: absolute;
		left: 0;
		top: 2.25em;
		width: 100%;
		height: 0;
		background-color: white;
		overflow: hidden;
		z-index: 2;
		@include transition('height', 0.3s, 'ease');
		
		.Select-option {
			cursor: pointer;
			position: relative;			
			padding: 0.56em 1em;
			height: 2.25em;
			span {
				line-height: 1;
			}
			&:hover {
				background-color: rgba(#253D8D, 0.1);
			}
			&--disabled {
				cursor: not-allowed;
				opacity: 0.5;
				&:hover {
					background-color: white;
				}
			}
		}
	}

	&.scenario,
	&.textToSpeech {
		height: 3em;
		margin-bottom: 2em;
		border: 1px solid #525252;
		border-radius: 0.4em;
		&.open {
			border-radius: 0.4em 0.4em 0 0;
			.Select-dropdown {
				height: auto;
				border-radius: 0 0 0.4em 0.4em;
				border: 1px solid black;
			}
		}
		.Select-panel {
			padding: 0 1em;
			@include flex('flex-start', 'center');
		}
		.Select-button {
			width: 3em;
			height: 3em;
			background-image: url('../../../assets/images/icons/icon-arrow-down.svg');
			background-position: top 1em right 0.75em;
			background-size: auto 0.75em;
		}
		.Select-dropdown { 
			top: calc(3em - 1px);
			.Select-option {
				height: 3em;
				padding: 0 1em;
				@include flex('flex-start', 'center');	
			}
		}
	}
	&.textToSpeech {
		height: 2.5em;
		.Select-button {
			width: 2.5em;
			height: 2.5em;
			background-position: top 0.75em right 0.75em;
		}
		.Select-dropdown { 
			top: calc(2.5em - 1px);
			.Select-option {
				height: 2.5em;
			}
		}
	}
}