@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.CookieConsent {

	.CookieConsent-toggleBtn {
		position: absolute;
		left: 1em;
		bottom: 1em;
		width: 4em;
		height: 4em;
		border-radius: 100%;
		background-size: 3.33em auto;
		background-image: url('../../../assets/images/icons/icon-cookies.svg');
		cursor: pointer;
		&:hover {
			background-size: 4em auto;
		}
	}

	.CookieConsent-popup {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($blueDark, 0.6);
		z-index: 13;
		@include flex('center', 'center');

		.CookieConsent-content {
			position: relative;
			width: 25em;
			max-width: calc(100% - 2em);
			min-height: 10em;
			color: $textDark;
			background-color: white;
			padding: 2em;
			border-radius: 1em;
			text-align: left;
			@include box-shadow(0, 0, 0.66em, 0, rgba(#000000, 0.2));

			.CookieConsent-title {
				font-size: 1.56em;
				font-weight: 700;
				margin-bottom: 0.75em;
			}
			.CookieConsent-text {
				font-size: 1em;
				font-weight: 500;
				margin-top: 0.5em;
			}

			.CookieConsent-buttons {
				margin-top: 2em;
				@include flex('space-between', 'center');

				.CookieConsent-button {
					width: 10em;
					max-width: calc(50% - 2em);
				}
			}
		}		
	}
}
