@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrderPushDndItem {
	position: relative;
	width: 100%;
	height: $orderItemHeight;
	line-height: 1.25;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	padding: 0.75em 3em 0.5em 3em;
	cursor: pointer;
}