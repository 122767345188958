@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.ProgressBar {
	position: relative;
	width: 100%;
	height: 1.5em;
	@include flex('center', 'center');

	&.portrait {
		.ProgressBar-track {
			height: 1.11em;
		}
	}

	.ProgressBar-track {
		position: relative;
		width: 100%;
		height: 2.22em;
		background-color: #222E5B;
		border: 0.36em solid rgba(#222E5B, 0.6);
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		border-radius: 1.11em;
		overflow: hidden;
		.ProgressBar-progress {
			position: relative;
			width: 0;
			height: 100%;
			border-radius: 1.11em;
			background-color: #8DB226;
			background-size: 100% 100%;
			@include transition(0.5s, width, ease-in-out);
			&::after {
				content: '';
				position: absolute;
				top: 0.25em;
				left: 0.25em;
				width: calc(100% - 0.5em);
				height: 0.33em;
				border-radius: 0.167em;
				background-color: rgba(#C9FF33, 0.4);
			}
		}
	}

	.ProgressBar-stars {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.ProgressBar-star {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			@include translate(-0.75em);
			@include flex('flex-start', 'center');
		}
	}
}