@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrderDndContainer {
	position: relative;
	width: 19.33em;
	height: 100%;
	&.final {
		background-color: rgba(#68778F, 0.5);
		border-radius: 0.33em;
		&.hover {
			background-color: rgba(#68778F, 0.6);
		}
	}
}