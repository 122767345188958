@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrderDndPreview {	
	.OrderDndPreview-item {
		position: relative;
		width: 19.33em;
		height: $orderItemHeight;
		padding: 0 2.75em 0 2.5em;
		line-height: 1;
		@include button-blue-dark();
		span {
			font-size: 1.33em;
			font-weight: 700;
		}		
		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 2.5em;
			left: 0;
			top: 0;
			cursor: move;
			background-image: url('../../../../../assets/images/icons/icon-drag.svg');
			background-size: 1.33em auto;
		}
	}
}