@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Feedback {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-image: linear-gradient(rgba(#273262, 0), #273262);
	background-size: 100% 44.2em;
	background-position: bottom center;
	z-index: 12;

	.Feedback-content {
		position: absolute;
		bottom: 2.33em;
		left: calc(50% - 31em);
		width: 22.7em;
		height: 30em;

		&.portrait {
			left: calc(50% - 13em);
			bottom: 1em;
			
			.Feedback-image {
				height: 7.8em;
				width: 7.8em;
			}
			.Feedback-text {
				p {
					font-size: 1.2em;
				}
			}
		}

		.Feedback-text {
			position: relative;
			padding-right: 4em;
			p {
				margin: 0;
				font-size: 1.33em;
				font-weight: 500;
				color: $textDark;
			}
		}
		.Feedback-audio {
			position: absolute;
			top: calc(50% - 1.125em);
			right: 1em;
		}
		.Feedback-image {
			position: absolute;
			width: 14em;
			height: 14em;
			bottom: 0;
			left: 2.33em;
			border-radius: 100%;
			border: 0.5em solid $blueDark;
			background-image: radial-gradient(#B5BCC7, #6A7992);
			background-size: 100% 100%;
			background-position: center center;
			overflow: hidden;
		}
	}
}