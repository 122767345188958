@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.SortDndContainer {
	position: relative;
	width: 100%;
	min-height: 8em;
	padding: 0.5em;
}