@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/animations';
.ChooseLanguage {
	height: 100%;
	width: 100%;
	@include flex('center', 'center');

	.ChooseLanguage-logoCGL {
		position: absolute;
		bottom: 2em;
		right: 2.25em;
		width: 11.11em;
		height: 2.25em;
		@include opacity(0.5);
	}

	.ChooseLanguage-content {
		position: relative;
		width: 33.33em;
		border-radius: 1.11em;
		background-color: rgba(white, 0.8);
		padding: 1.5em 1.5em 3em 1.5em;
		@include box-shadow(0, 0.167em, 0.33em, 0, rgba(black, 0.2));
		@include flex('', 'center', column);
		.ChooseLanguage-title,
		.ChooseLanguage-subtitle {
			span {
				color: $textDark2;
				font-size: 2.22em;
				font-weight: 900;
			}
		}
		.ChooseLanguage-subtitle span {
			color: $textDark;
		}
		.ChooseLanguage-languages {
			position: relative;
			margin-top: 2em;
			@include flex();
			flex-wrap: wrap;
			width: calc((8.38em + 0.35em * 2) * 2);
			.ChooseLanguage-language {
				display: inline-block;
				vertical-align: top;
				width: 8.38em;
				height: 4.86em;
				margin: 0.35em 0.35em;
				cursor: pointer;
				background-size: cover;
				&.da {
					background-image: url('../../assets/images/languages/da.svg');
				}
				&.en {
					background-image: url('../../assets/images/languages/en.svg');
				}
			}
		}
	}

	.ChooseLanguage-logoutBtn {
		position: absolute;
		left: 2.75em;
		bottom: 2em;
	}
}