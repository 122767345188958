@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Manager {
	width: 23em;
	height: 42.3em;
	&.portrait {
		width: 23em;
		height: 42.3em;

		&.feedback {
			width: 11.52em;
			height: 21.16em;
			margin-left: -1.5em;
			margin-top: -1em;
		}
	}
	svg {
		display: block;
		width: 100%;
		height: auto;
		overflow: visible;
		#pose-a-1-body,
		#pose-a-1-coat,
		#pose-a-1-coat-grey,
		#pose-a-2-body,
		#pose-a-2-coat,
		#pose-a-2-coat-grey,
		#burned,
		#hairnet-a-1 {
			display: none;
		}

		#blink {
			display: block;
		}
	}

	&:not(.feedback) {
		@include opacity(0);
		@include fadeIn(1s);
	}

	&.fadeOut {
		@include fadeOut(1s);
	}

	&.manager-burned,
	&.manager,
	&.manager-a {
		&.pose-1 svg #pose-a-1-body,
		&.coat-1 svg #pose-a-1-coat-grey,
		&.pose-2 svg #pose-a-2-body,
		&.coat-2 svg #pose-a-2-coat-grey,
		&.hairnet-1 svg #hairnet-a-1 {
			display: block;
		}

		&.blinking {
			svg #blink {
				display: none;
			}
		}
	}

	&.feedback {
		width: 20em;
		height: 23.16em;
		margin-left: -1.75em;
		margin-top: -0.5em;
	}
}