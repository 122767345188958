@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/animations';
.FacilitatorHeader {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #f2482b;
	@include flex('center', 'center');
	.FacilitatorHeader-logoAndTitle {
		position: absolute;
		top: 1em;
		left: 2em;
		text-align: left;
		.FacilitatorHeader-logo {
			width: 12em;
			height: 6em;
		}
		.FacilitatorHeader-title {
			@include text-shadow(0.11em, 0.11em, 0.11em, #12283E);
			span {
				color: #ffffff;
				font-size: 2em;
				font-weight: bold;
			}
		}
		.FacilitatorHeader-subtitle {
			@include text-shadow(0.11em, 0.11em, 0.11em, #12283E);
			span {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: bold;
			}
		}
	}
	.FacilitatorHeader-gameInfo {
		position: absolute;
		top: 2em;
		margin: 0 auto;
		// right: calc(50% - 10em);
		// width: 20em;
		// height: calc(100% - 4em);
		color: white;
		.FacilitatorHeader-gameName,
		.FacilitatorHeader-gameUrl {
			white-space: nowrap;
			overflow: hidden;
			text-align: left;
			margin: 0.5em 0;
			span {
				display: inline-block;
				font-size: 1.25em;
				color: white;
				text-decoration: none;
				&:nth-child(1) {
					font-weight: 600;
					text-transform: uppercase;
					width: 7em;
				}
			}
		}
		.FacilitatorHeader-backBtn {
			width: 12em;
			margin: auto;
			margin-top: 2em;
			color: #525252;
		}
	}

	.FacilitatorHeader-userInfo {
		position: absolute;
		top: 1em;
		right: 5em;
		text-align: right;
		span {
			display: block;
			color: white;
			font-size: 0.89em;
			&:last-of-type {
				font-weight: bold;
				font-size: 1em;
			}
		}
	}

	.FacilitatorHeader-logoutBtn {
		position: absolute;
		top: 1em;
		right: 1em;
	}

	.FacilitatorHeader-languageBtn {
		position: absolute;
		top: 5.5em;
		right: 1em;
		width: 3em;
		height: 1.5em;
		cursor: pointer;
		background-size: contain;
		&.da {
			background-image: url('../../assets/images/languages/da.svg');
		}
		&.en {
			background-image: url('../../assets/images/languages/en.svg');
		}
	}

	.FacilitatorHeader-adminBtn {
		position: absolute;
		width: 6em;
		bottom: 1em;
		right: 1em;
	}
}