@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrderDndItem {
	position: relative;
	width: 19.33em;
	height: $orderItemHeight;
	text-align: center;
	padding: 0 2.75em 0 2.5em;
	line-height: 1;
	cursor: move;
	@include button-blue-dark();

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 2.5em;
		height: 100%;
		background-size: contain;
		background-image: url('../../../../../assets/images/icons/icon-drag.svg');
		background-size: 1.33em auto;
	}
	
	span {
		
		font-size: 1.33em;
		font-weight: 700;
	}
	&.disabled {
		cursor: not-allowed;
		background-image: none;
	}	
	&.correct {
		@include button-green();
	}
	&.wrong {
		@include button-red();
	}

	.OrderDndItem-audio {
		position: absolute;
		right: 0.5em;
		top: calc(50% - 1em);
	}

}