@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.SpotErrors {
	position: relative;
	height: 100%;
	overflow: auto;
	padding-top: 5em;
	background-color: white;
	@include opacity(0);
	@include fadeIn(0.5s);
		
	.SpotErrors-intro {
		max-width: 55.56em;
		width: calc(100% - 2em);
		margin: 0 auto;
		background-color: rgba(white, 0.8);
		border-radius: 1.11em;
		margin-bottom: 1em;
		@include box-shadow(0, 0.17em, 0.33em, 0, rgba(black, 0.2));
	}

	.SpotErrors-errors {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.SpotErrors-error {
			position: absolute;
			width: 5em;
			height: 5em;
			border-radius: 100%;
			border: 0 solid #11466B;
			&.spotted {
				background-image: url('../../../../../assets/images/icons/icon-correct.svg');
				background-position: center center;
				background-size: 2em auto;
				@include no-box-shadow();
				@include scale(0);
				@include popInImage(0.5s);
			}
		}
	}

	.SpotErrors-confirmBtn {
		position: fixed;
		right: calc(50% - 0.5 * 8.78em);
		bottom: 1.44em;
		height: 2.67em;
		width: 8.78em;
		cursor: pointer;
		@include button-blue-dark();
		span {
			font-size: 1.33em;
			font-weight: bold;
		}
	}

	&.completed {
		.SpotErrors-error.spotted {
			background-size: 2em auto;
			@include noAnimation();
			@include scale(1);
		}
		.SpotErrors-error:not(.spotted) {
			&.isError {
				@include box-shadow(0, 0, 0em, 0.22em, #fff);
				@include popIn(0.5s);
			}
		}
	}

	&.s1-m2-grill-hygiene {
		background-color: transparent;
		background-image: url('../../../../../assets/images/modules/tasks/spot-errors/s1-m2-grill-hygiene/background.svg');
		background-size: 32.22em auto;
		background-position: bottom -2.8em left calc(50% - 2.25em);
		.SpotErrors-errors { 
			.SpotErrors-error {
				&.raw-meat-bread {
					bottom: 4em;
					left: calc(50% - 5.25em);
					width: 12em;
					height: 5.5em;
				}
				&.raw-meat-done-meat {
					bottom: 9em;
					left: calc(50% + 3em);
					width: 9em;
					height: 3.75em;
				}
				&.unclean-utencils {
					bottom: 12.25em;
					left: calc(50% + 0.9em);
					width: 4em;
					height: 4em;
				}
				&.seagull {
					bottom: 30.75em;
					left: calc(50% - 12.1em);
					width: 5.5em;
					height: 5em;
				}
			}
		}
	}

	.SpotErrors-doneBtn {
		position: fixed;
		right: calc(50% - 7em / 2);
		bottom: 0.5em;
		z-index: 2;
		width: 7em;
	}
}