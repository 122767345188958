@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrganizeDndDragLayer {
	.OrganizeDndDragLayer-item { 
		position: relative;
		display: inline-block;
		.OrganizeDndDragLayer-img {
			width: 100%;
			height: 100%;
			position: relative;
		}
	}

	&.s1-m2-grilling-amount {
		z-index: 99;
		.OrganizeDndDragLayer-item {
			width: 4.5em;
			height: 5.5em;
			.OrganizeDndDragLayer-img {
				height: 5em;
				width: 7.5em;
				&.chicken {
					background-size: 5.3em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/chicken-raw.svg');
				}
				&.sausage {
					background-size: 6.8em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/sausage-raw.svg');
				} 
				&.mince {
					background-size: 5.1em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/mince-raw.svg');
				} 
				&.beef {
					background-size: 4.5em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/beef-raw.svg');
				} 
				&.squash {
					background-size: 7.2em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/squash-raw.svg');
				} 
				&.carton {
					background-size: 5.8em auto;
					background-position: center;
					background-image: url('../../../../../assets/images/modules/tasks/organize/s1-m2-grilling-amount/carton-raw.svg');
				} 
			}
		}
	}
}