@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.AdminHeader {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #f2482b;
	.AdminHeader-logoAndTitle {
		position: absolute;
		top: 1em;
		left: 2em;
		.AdminHeader-logo {
			width: 12em;
			height: 6em;
		}
		.AdminHeader-title {
			@include text-shadow(0.11em, 0.11em, 0.11em, #12283E);
			span {
				color: #ffffff;
				font-size: 2em;
				font-weight: bold;
			}
		}
	}
	.AdminHeader-gameInfo {
		position: absolute;
		top: 2em;
		right: calc(50% - 10em);
		width: 20em;
		height: calc(100% - 4em);
		color: white;
		@include flex('center', 'flex-start', 'column');
			> div {
				white-space: nowrap;
				overflow: hidden;
				text-align: left;
				margin: 0.5em 0;
				span, a {
					display: inline-block;
					font-size: 1.25em;
					color: white;
					text-decoration: none;
					&:nth-child(1) {
						font-weight: 600;
						text-transform: uppercase;
						width: 7em;
					}
				}
				a:hover {
					text-decoration: underline;
				}
			}
	}

	.AdminHeader-userInfo {
		position: absolute;
		top: 1em;
		right: 5em;
		text-align: right;
		span {
			display: block;
			color: white;
			font-size: 0.89em;
			&:last-of-type {
				font-weight: bold;
				font-size: 1em;
			}
		}
	}

	.AdminHeader-logoutBtn {
		position: absolute;
		top: 1em;
		right: 1em;
	}

	.AdminHeader-languageBtn {
		position: absolute;
		top: 5.5em;
		right: 1em;
		width: 3em;
		height: 1.5em;
		cursor: pointer;
		background-size: contain;
		&.da {
			background-image: url('../../../assets/images/languages/da.svg');
		}
		&.en {
			background-image: url('../../../assets/images/languages/en.svg');
		}
	}

	.AdminHeader-cleanupButton {
		position: absolute;
		width: 10em;
		bottom: 1em;
		right: 8em;
	}

	.AdminHeader-facilitatorBtn {
		position: absolute;
		width: 6em;
		bottom: 1em;
		right: 1em;
	}
}