@mixin button() {
	border-radius: 0.33em;
	@include box-shadow-inset-inset-outset(
		0, 0.25em, 0, 0, rgba(white, 0.3),
		0, -0.28em, 0, 0, rgba(#000000, 0.2),
		0, 0.17em, 0.33em, 0, rgba(#000000, 0.2)
	);
	@include flex('center', 'center');
	@include no-select();
}

@mixin button-blue() {
	@include button();
	background-image: linear-gradient(#7395C5, #5670A4);
	background-color: $blue;

	span {
		color: white;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: darken($blue, 3%);
	}
}

@mixin button-blue-dark() {
	@include button();
	background-image: linear-gradient(#4355A7, #273262);
	background-color: $blueDark;
	span {
		color: white;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: darken($blueDark, 3%);
	}
}

@mixin button-red() {
	@include button();
	background-color: $red;
	background-image: linear-gradient(#FB6453, #DD3426);
	span {
		color: white;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: darken($red, 3%);
	}
}

@mixin button-green() {
	@include button();
	background-color: $green;
	background-image: linear-gradient(#ACD42F, #8DB126);
	span {
		color: white;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: darken($green, 3%);
	}
}