@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.Sort {
	position: relative;
	height: 100%;
	padding: 7.5em 0 0.5em 0;
	overflow: hidden;
	@include flex('center', 'flex-start');

	.Sort-content {
		position: relative;
		width: 55.56em;

		.Sort-intro {
			position: relative;
			width: 100%;
			margin: 0 auto;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			margin-bottom: 1em;
			@include box-shadow(0, 0.17em, 0.33em, 0, rgba(black, 0.2));
			.Sort-audio {
				position: absolute;
				top: calc(50% - 1.36em);
				right: 1em;
			}
		}

		.Sort-itemsAndCategories {
			position: relative;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			border: 0.17em solid #12283E;
			padding: 2em;
			margin: 0 auto;
			@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);
			@include flex('space-between', 'flex-start');
			.Sort-categories {
				position: relative;
				width: 25.5em;
				.Sort-category {
					position: relative;
					margin-bottom: 1em;
					&:last-of-type {margin-bottom: 0;}
					.Sort-categoryTitle {
						position: relative;
						height: 2em;
						background-color: $blue;
						border-top-left-radius: 0.5em;
						border-top-right-radius: 0.5em;
						@include flex('center', 'center');
						@include no-select();
						span {
							font-size: 1.3em;
							color: white;
							font-weight: 700;
						}
					}
					.Sort-categoryBody {
						position: relative;
						min-height: 8em;
						border: 1px solid $blue;
						border-bottom-left-radius: 0.5em;
						border-bottom-right-radius: 0.5em;
					}
				}
			}
			.Sort-items {
				position: relative;
				width: 25.5em;
				@include flex('flex-start', 'flex-end', 'column');
			}
		}
	}
	.Sort-audio {
		position: absolute;
		right: 0.5em;
		top: calc(50% - 1em);
	}
}