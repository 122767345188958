@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/animations';
.LoginController {
	position: relative;
	height: 100%;
	width: 100%;
	&.shake {
		@include shake();
	}
}

.Login {
	position: relative;
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	@include flex('center', 'center');
	.Login-logo {
		position: absolute;
		bottom: 43.33em;
		width: 19.33em;
		height: 11.56em;
	}
	.Login-title {
		position: absolute;
		bottom: 38.3em;
		left: 0;
		width: 100%;
		height: 3.33em;
		text-align: center;
		span {
			color: white;
			font-weight: 500;
			font-size: 3.33em;
		}
	}
	.Login-logoCGL {
		position: absolute;
		bottom: 2em;
		right: 2.25em;
		width: 11.11em;
		height: 2.25em;
		@include opacity(0.5);
	}
}