@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.AdminExportModulePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');

	.AdminExportModulePopup-content {
		width: 30em;
		min-height: 14em;
		background-color: white;
		border-radius: 0.83em;

		.AdminExportModulePopup-header {
			margin-top: 0.75em;
			span {
				font-size: 1.9em;
				font-weight: bold;
			}
		}

		.AdminExportModulePopup-moduleList {
			padding: 1em;
			.AdminExportModulePopup-module {
				padding: 1em;
				padding-bottom: 0.5em;
				padding-top: 0.5em;
				@include flex('space-between', 'center');
				
				&:nth-child(odd) {
					background-color: rgba(#f2482b, 0.2);
				}
			}
		}

		.AdminExportModulePopup-downloadButton {
			margin: auto;
			margin-bottom: 1em;
			width: 10em;
		}
	}
}
