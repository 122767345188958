@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.AdminAddFacilitatorPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');

	.AdminAddFacilitatorPopup-content {
		width: 25em;
		background-color: white;
		border-radius: 0.83em;

		.AdminAddFacilitatorPopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #f2482b;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			padding-left: 2em;
			@include flex('flex-start', 'center');
			.AdminAddFacilitatorPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.AdminAddFacilitatorPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../assets/images/icons/icon-cross-white.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.AdminAddFacilitatorPopup-body {
			padding: 1.5em;
			.AdminAddFacilitatorPopup-input {
				width: 100%;
				input {
					width: 100%;
					padding: 1em;
					font-size: 0.8em;
					border: 1px solid #525252;
					border-radius: 0.5em;
				}
			}
			.AdminAddFacilitatorPopup-feedback {
				width: 100%;
				height: 2em;
				span {
					font-size: 0.8em;
					color: black;
				}
				&.error span {color: #C13B3B;}
			}
			.AdminAddFacilitatorPopup-confirmBtn {
				margin: 0 auto;
				width: 10em;
			}
		}
	}
}