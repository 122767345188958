@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.MultipleChoice {
	position: relative;
	height: 100%;
	padding: 7.5em 0 0.5em 0;
	overflow: hidden;
	@include flex('center', 'flex-start');

	&.portrait {
		padding: 3.25em 0 0.5em 0;
		.MultipleChoice-content {
			.MultipleChoice-intro {
				width: calc(100% - 2em);
			}
		}
	}

	.MultipleChoice-content {
		width: 55.56em;
		overflow: auto;
		@include hide-scrollbar();
		.MultipleChoice-intro {
			width: 100%;
			margin: 0 auto;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			margin-bottom: 1em;
			@include box-shadow(0, 0.17em, 0.33em, 0, rgba(black, 0.2));
		}
		.MultipleChoice-options {
			position: relative;
			width: calc(100% - 2em);
			.MultipleChoice-optionsWrap {
				position: relative;
				@include opacity(0);
				@include fadeIn(0.5s, 0s);
				.MultipleChoice-option {
					position: relative;
					width: 100%;
					height: 100%;
					text-align: center;
					padding: 0.75em 2.25em 0.5em 1.25em;
					cursor: pointer;
					@include flex('center', 'center');
					@include no-select();
					&.animateCorrect {@include bounce();}
					&.animateWrong {@include wobble();}
					&.disabled {cursor: not-allowed;}
					span:first-of-type {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
					span:last-of-type {
						font-size: 1.33em;
						font-weight: bold;
					}
					.MultipleChoice-audio {
						position: absolute;
						right: 0.5em;
						top: calc(50% - 1em);
					}
				}
			}
		}
	}

	&:not(.images) .MultipleChoice-content {
		@include flex('', 'center', 'column');
		.MultipleChoice-options {
			padding: 1.11em 0;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			.MultipleChoice-optionsWrap {
				width: 19.33em;
				margin: 0 auto;
				.MultipleChoice-option {
					min-height: 3em;
					margin-bottom: 1.25em;
					padding-right: 2.5em;
					@include button-blue-dark();
					&:last-of-type {
						margin-bottom: 0;
					}
					&.correct,
					&.animateCorrect {
						@include button-green();
					}
					&.wrong,
					&.animateWrong {
						@include button-red();
					}
					&.completed {
						cursor: not-allowed;
					}
				}
			}
		}
	}

	&.images .MultipleChoice-content {
		.MultipleChoice-options {
			padding: 0;
			.MultipleChoice-optionsWrap {
				.MultipleChoice-option { 
					min-height: auto;
					background: none;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					padding: 0;
					border: 0.44em solid transparent;
					
					width: 16.11em;
					height: 16.11em;
					margin: 0 0.3em 0.6em 0.3em;
					background-color: white;
					border-radius: 1.11em;
					@include box-shadow(0, 0, 0, 0, transparent);
					&::after {
						content: '';
						display: none;
						position: absolute;
						top: 0.25em;
						right: 0.25em;
						width: 2.22em;
						height: 2.22em;
						background-size: contain;
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						@include box-shadow(0, 0, 0, 0, transparent);
						&::after {display: block;}
					}
					&.correct,
					&.animateCorrect {
						border-color: $green;
						&::after {
							background-image: url('../../../../../assets/images/icons/icon-correct.svg');
						}
					}
					&.wrong,
					&.animateWrong {
						border-color: $redDark;
						&::after {
							background-image: url('../../../../../assets/images/icons/icon-wrong.svg');
						}
					}
					&.completed {
						cursor: not-allowed;
					}
				}
			}
		}
	}
	
	&.s1-m1-handwashing .MultipleChoice-content {
		.MultipleChoice-options {
			max-width: 55.56em;
			width: calc(100% - 2em);
			margin: 0 auto;
			
			.MultipleChoice-optionsWrap {
				position: relative;
				margin: 0 auto;
				width: 100%;
				height: 28.89em;
				border-radius: 1.11em;
				background-color: white;
				background-size: 21.67em auto;
				background-position: center center;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-handwashing/background.svg');

				.MultipleChoice-option {
					border-radius: 100%;
					background: none;
					border: 0.25em solid #6A7992;

					&.option-1 { // Håndfladen
						position: absolute;
						top: 18em;
						left: calc(50% + 0.25em);
						width: 7.25em;
						height: 7.25em;
					}
					&.option-2 { // Håndryggen
						position: absolute;
						top: 3.5em;
						left: calc(50% - 8.75em);
						width: 8.25em;
						height: 7.25em;
					}
					&.option-3 { // Neglene
						position: absolute;
						top: 9.5em;
    					left: calc(50% + 5.5em);
						width: 2.5em;
						height: 2.5em;
					}
					&.option-4 { // Håndled
						position: absolute;
						top: 19em;
						left: calc(50% + 8em);
						width: 2.5em;
						height: 6.5em;
					}
					&.option-5 { // Mellem fingre
						position: absolute;
						top: 7.75em;
						left: calc(50% - 0.5em);
						width: 2.5em;
						height: 2.5em;
					}
					&.option-6 { // Tommel
						position: absolute;
						top: 11.75em;
						left: calc(50% + 0.5em);
						width: 3.25em;
						height: 3.25em;
					}
					&.option-7 { // Lillefinger
						position: absolute;
						top: 25em;
						left: calc(50% - 4em);
						width: 3.25em;
						height: 3.25em;
					}
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						border: 0.25em solid #6A7992;
						&::after {
							top: calc(50% - 1.11em);
							left: calc(100% - 0.5em);
						}
					}
				}
			}	
		}
	}

	&.s1-m1-grill-clothing .MultipleChoice-content {
		.MultipleChoice-options {
			width: 22.2em;
			margin: 0 auto;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;

				.MultipleChoice-option {
					width: 10.5em;
					height: 10.5em;
					span:first-of-type {
						background-position: center center;
					}
					&.option-1 span:first-of-type { // Kiss the cook-forklæde
						background-size: 6.86em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-grill-clothing/option-1.svg');
					}
					&.option-2 span:first-of-type { // Skudsikker vest
						background-size: 6.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-grill-clothing/option-2.svg');
					}
					&.option-3 span:first-of-type { // Branddragt
						background-size: 7.2em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-grill-clothing/option-3.svg');
					}
					&.option-4 span:first-of-type { // Bar mave
						background-size: 9em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-grill-clothing/option-4.svg');
					}
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						&::after {
							top: calc(0% + 0.5em);
							right: calc(0% + 0.5em);
						}
					}
				}
			}
		}
	}

	&.s1-m1-lighting-fuel .MultipleChoice-content {
		.MultipleChoice-options {
			width: 22.5em;
			margin: 0 auto;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;

				.MultipleChoice-option {
					width: 10.5em;
					height: 10.5em;
					span:first-of-type {
						background-position: center center;
					}
					&.option-1 span:first-of-type { // Benzin
						background-size: 6.24em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-lighting-fuel/option-1.svg');
					}
					&.option-2 span:first-of-type { // Sprit
						background-size: 3.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-lighting-fuel/option-2.svg');
					}
					&.option-3 span:first-of-type { // Tændvæske
						background-size: 3.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-lighting-fuel/option-3.svg');
					}
					&.option-4 span:first-of-type { // Optændingsblokke
						background-size: 7.6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-lighting-fuel/option-4.svg');
					}
					&.option-5 span:first-of-type { // Avispapir
						background-size: 9em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-lighting-fuel/option-5.svg');
					}
					&.option-6 span:first-of-type { // Danskvand
						background-size: 2.7em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/s1-m1-lighting-fuel/option-6.svg');
					}
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						&::after {
							top: calc(0% + 0.5em);
							right: calc(0% + 0.5em);
						}
					}
				}
			}
		}
	}
}