@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.AdminGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');

	.AdminGamePopup-content {
		width: 80em;
		height: 35em;
		max-height: 100vh;
		background-color: white;
		border-radius: 0.83em;

		.AdminGamePopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #f2482b;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			.AdminGamePopup-storeName {
				position: absolute;
				left: 2.56em;
				top: 0;
				bottom: 0;
				@include flex('flex-start', 'center');
				span {
					display: inline-block;
					color: #ffffff;
					font-size: 1.4em;
					font-weight: 600;
					margin-right: 1em;
				}
				input {
					width: 100%;
					color: #000000;
					font-size: 1.25em;
				}				
			}
			.AdminGamePopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../assets/images/icons/icon-cross-white.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.AdminGamePopup-body {
			position: relative;
			height: calc(100% - 4.25em);
			padding: 1.5em 2.5em 5em 2.5em;
			text-align: left;

			.AdminGamePopup-gameInfo {
				margin-bottom: 4em;
				.AdminGamePopup-gameInfoRow {
					margin-bottom: 0.5em;
					.AdminGamePopup-gameInfoLabel {
						width: 10em;
					}
					.AdminGamePopup-gameInfoLabel,
					.AdminGamePopup-gameInfoValue {
						display: inline-block;
						vertical-align: top;
						span,
						a {
							color: #525252;
							font-size: 1.25em;

						}
					}
				}
			}

			.AdminGamePopup-addFacilitatorBtn {
				width: 10.83em;
				height: 2.67em;
				margin-bottom: 0.5em;
			}
			.AdminGamePopup-facilitators {
				.AdminGamePopup-facilitatorsCell {
					width: 30%;
					height: 100%;
					padding: 0 1.5em;
					@include flex('flex-start', 'center');
			
					&.created,
					&.lastlogin {
						width: 20%;
					}
					.AdminGamePopup-removeFacilitatorBtn {
						width: 10em;
						height: 2em;		
					}
				}
				.AdminGamePopup-facilitatorsHeader {
					width: 100%;
					height: 2.33em;
					color: white;
					background-color: #f2482b;
					@include flex('space-between', 'center');
					.AdminGamePopup-facilitatorsCell {
						span {
							display: inline-block;
							vertical-align: middle;
							position: relative;
							font-size: 1.39em;
							font-weight: 600;
			
						}
					}
				}
				.AdminGamePopup-facilitatorsBody {
					width: 100%;
					.AdminGamePopup-facilitatorsRow {
						width: 100%;
						height: 2.5em;
						color: #525252;
						@include flex('space-between', 'center');
						&:nth-child(odd) {
							background-color: rgba(#f2482b, 0.2);						
						}
						.AdminGamePopup-facilitatorsCell.remove {
							@include flex('flex-end', 'center');
						}
					}
				}
			}

			.AdminGamePopup-deleteGameBtn {
				position: absolute;
				bottom: 1em;
				right: 1em;
				width: 10.83em;
				height: 2.67em;
			}
		}
	}
}