@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/animations';
.OrderPushDndItems-items {
	position: absolute;
	width: calc(100% - 2em);
	height: fit-content;
	margin: 0.75em auto auto auto;
	overflow: auto;
	padding: 0.5em;
	padding-top: 1em;
	padding-bottom: 1em;
	border-radius: 0.5em;
	background-color: rgba(white, 0.8);
	@include hide-scrollbar();

	.OrderPushDndItems-item {
		width: 100%;
		height: $orderItemHeight;
		margin-bottom: 0.8em;
		@include flex('space-between', 'center');

		&.isLast {
			margin-bottom: 0;
		}

		.OrderPushDndItems-itemNumber {
			width: 2.4em;
			height: 2.4em;
			text-align: left;
			margin-right: 0.6em;
			border-radius: 0.7em;
			@include flex('center', 'center');

			span {
				font-size: 1.07em;
				font-weight: bold;
				color: $textDark;
			}
		}
		.OrderPushDndItems-itemContainer {
			position: absolute;
			width: calc(100% - 5em);
			left: 4em;

			&.location-0 {top: 1em;}
			&.location-1 {top: 5.8em + 1em;}
			&.location-2 {top: calc(2 * 5.8em + 1em);}
			&.location-3 {top: calc(3 * 5.8em + 1em);}
			&.location-4 {top: calc(4 * 5.8em + 1em);}
			&.location-5 {top: calc(5 * 5.8em + 1em);}
			&.location-6 {top: calc(6 * 5.8em + 1em);}
			&.location-7 {top: calc(7 * 5.8em + 1em);}
			&.location-8 {top: calc(8 * 5.8em + 1em);}
		}

		.OrderPushDndItems-graphicContainer {
			position: absolute;
			width: calc(100% - 5em);
			left: 4em;
			pointer-events: none;

			@include transition(0.2s, 'top');

			&.location-0 {top: 1em;}
			&.location-1 {top: 5.8em + 1em;}
			&.location-2 {top: calc(2 * 5.8em + 1em);}
			&.location-3 {top: calc(3 * 5.8em + 1em);}
			&.location-4 {top: calc(4 * 5.8em + 1em);}
			&.location-5 {top: calc(5 * 5.8em + 1em);}
			&.location-6 {top: calc(6 * 5.8em + 1em);}
			&.location-7 {top: calc(7 * 5.8em + 1em);}
			&.location-8 {top: calc(8 * 5.8em + 1em);}
		}
	}
}