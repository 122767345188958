@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 0.4;
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: center right;
		}	
	}

	&.default {
		@include button();
		background-color: white;
		&:hover,
		&:focus,
		&:active {
			background-color: darken(white, 5%);
		}
		span {
			color: black;
		}
	}

	&.login {
		@include button-blue-dark();
		height: 3em;
		span {
			font-size: 1.33em;
			font-weight: 700;
		}
		&.submit {
			border: none;
		}
		&.loading {
			&::after {
				background-image: url('../../../assets/images/icons/icon-loading-white.svg');
			}	
		}
	}

	&.logout {
		width: 2.5em;
		height: auto;
		padding-top: 2em;
		border-radius: 0;
		background-image: url('../../../assets/images/icons/icon-logout.svg');
		background-size: 2em auto;
		background-position: top right;
		@include flex('flex-end', 'center');
		span {
			color: white;
			font-size: 0.6em;
		}
	}

	&.logout-player,
	&.reset {
		height: 3em;
		width: 15.11em;
		max-width: 100%;
		@include button-blue-dark();
		border-radius: 0.33em;
		background-image: url('../../../assets/images/icons/icon-logout-2.svg');
		background-size: 1.88em auto;
		background-position: left 0.5em center;
		span {
			font-size: 1.33em;
			font-weight: bold;
		}
	}
	&.reset {
		background-image: none;
		width: 11.11em;
	}

	&.switch-language {
		width: 3.33em;
		height: 3.33em;
		background-size: contain;
		background-image: url('../../../assets/images/icons/icon-language.svg');
	}

	&.facilitator {
		width: 100%;
		height: 100%;
		background-color: #f2482b;
		padding: 0.5em;
		span {
			color: white;
			font-size: 1.11em;
			font-weight: bold;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#f2482b, 5%);
		}
		&.loading {
			&::after {
				background-image: url('../../../assets/images/icons/icon-loading-white.svg');
			}	
		}
	}

	&.next,
	&.confirmTask {
		@include button-blue-dark();
		position: absolute;
		width: 19.33em;
		height: 3em;
		bottom: 2.33em;
		left: calc(50% - (0.5 * 19.33em));
		span {
			font-weight: 500;
		}
		&.delay {
			@include delayButton();
		}
		&.animate {
			@include opacity(0);
			animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
			-webkit-animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
		}
	}

	&.next {
		background-image: url('../../../assets/images/icons/icon-arrow-right.svg');
		background-size: auto 1.5em;
		background-position: center right 1em;
		background-repeat: no-repeat;
	}

	&.togglePlayerStatus {
		height: 1.5em;
		padding: 0.25em 0.5em;
		span {
			font-size: 0.8em;
		}
	}

	&.small {
		left: calc(50% - 1.75em);
		width: 3.5em;
		height: 2em;
		span {
			font-size: 1.2em;
		}
	}

	&.blue {
		@include button-blue-dark();
		border-radius: 2em;
		height: 2.8em;
	}

	&.yellow {
		color: #12283E;
		background-image: linear-gradient(#F8D949, #ECBD41);
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken(#F8D949, 3%), darken(#ECBD41, 3%));
		}
	}

	&.blueDark {
		@include button-blue-dark();
	}

	&.green {
		@include button-green();
	}

	&.red {
		@include button-red();
	}

	&.bold {
		span {
			font-weight: bold;
		}
	}

	&.default-case {
		span {
			text-transform: none;
		}
	}
}