@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/buttons';
@import '../../../../styles/animations';
.AdminDownloadStats {
	.AdminDownloadStats-btn {
		font-size: 1.11em;
		width: 12em;
		height: 2em;
		border-radius: 0.5em;
		background-color: #f2482b;
		color: white;
		border-style: none;
		font-weight: bold;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		@include no-select();
		@include opacity(1);
		@include flex('center', 'center');
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#f2482b, 5%);
		}
		&.loading {
			&::after {
				background-image: url('../../../../assets/images/icons/icon-loading-white.svg');
			}	
		}
	}
}