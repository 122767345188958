@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.Module {
	position: relative;
	height: 100%;
	overflow: auto;
	@include hide-scrollbar();
	.Module-header {
		position: absolute;
		top: 0;
		width: 100%;
		padding-top: 2.22em;
		z-index: 11;
		pointer-events: none;
		@include flex('center', 'flex-start');
	
		.Module-homeBtn {
			position: absolute;
			width: 4.5em;
			height: 4.5em;
			top: 0;
			left: 0;
			background-size: 3.5em auto;
			background-position: center center;
			pointer-events: all;
			cursor: pointer;
			background-image: url('../../../assets/images/icons/icon-home.svg');
			
		}
		.Module-progressBar {
			position: relative;
			width: 39em;
		}

		&.portrait {
			padding-top: 1.1em;
			padding-right: 1em;
			right: 0;
			@include flex('flex-end', 'flex-start');
			
			.Module-progressBar {
				width: calc(100% - 4.5em);
			}

			.Module-homeBtn {
				width: 2.5em;
				height: 2.5em;
				background-size: 2.5em auto;
				top: 0.65em;
				left: 0.5em;
			}
		}
	}
	.Module-nextBtn {
		z-index: 11;
	}
}