@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.AdminAddGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');
	.AdminAddGamePopup-content {
		width: 25em;
		background-color: white;
		border-radius: 0.83em;

		.AdminAddGamePopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #f2482b;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			padding-left: 2em;
			@include flex('flex-start', 'center');
	
			.AdminAddGamePopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			
			.AdminAddGamePopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../assets/images/icons/icon-cross-white.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}
	
		.AdminAddGamePopup-body {
			padding: 1.5em;
	
			.AdminAddGamePopup-input {
				width: 100%;
				.AdminAddGamePopup-inputField {
					border: 1px solid #525252;
					border-radius: 0.4em;
					padding: 0.8em;
					&.invalid {border-color: #F8937B;}
					&.gameUrl {
						@include flex('flex-start', 'center');
						input {
							flex: 1;
						}
					}
					span {
						display: inline-block;
						vertical-align: middle;
						font-size: 0.8em;
					}
					input {
						display: inline-block;
						vertical-align: middle;
						width: 100%;
						font-size: 0.8em;						
						border-radius: 0.5em;
					}
				}				
				.AdminAddGamePopup-warning {
					height: 2em;
					span {
						font-size: 0.8em;
						color: #F8937B;
					}
				}
			}
			.AdminAddGamePopup-errorMsg {
				height: 2em;
				span {
					font-size: 0.8em;
					color: #F8937B;
				}
			}
			.AdminAddGamePopup-createGameBtn {
				width: 10em;
				margin: 1em auto;
			}
		}
	}
}