@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.StartGame {
	position: absolute;
	height: 100%;
	width: 100%;
	@include flex('space-between', 'center', 'column');
	padding-bottom: 1em;
	padding-top: 3.6em;

	.StartGame-textWrapper {
		position: absolute;
		z-index: 1;
		bottom: 33em;
		@include flex('', 'center', 'column');
		.StartGame-title {
			color: $textDark;
			padding-bottom: 0.5em;
			span {
				font-size: 1.27em;
				font-weight: bold;
			}
		}
		.StartGame-text1 {
			color: $textDark;
			width: 19.3em;
			span {
				font-weight: 500;
			}
		}
	}
	.StartGame-grill {
		position: absolute;
		z-index: 1;
		background-image: url(../../../assets/images/startGame-grill-smoke.svg);
		bottom: -6.3em;
		left: calc(50% - 12.87em);
		width: 25.65em;
		height: 52.4em;
	}

	.StartGame-header {
		z-index: 2;
		background-image: url(../../../assets/images/grill-title.svg);
		width: 13.2em;
		height: 6.6em;
	}

	.StartGame-footer {
		z-index: 2;
		@include flex('', 'center', 'column');
		.StartGame-resetBtn,
		.StartGame-startButton {
			position: relative;
			width: 10.7em;
			bottom: 7.3em;
		}
	}
}