@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/animations';
.FacilitatorSurveysPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');

	.FacilitatorSurveysPopup-content {
		width: 50em;
		min-height: 14em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorSurveysPopup-header {
			position: relative;
			width: 100%;
			height: 3em;
			background-color: #f2482b;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			border-bottom-width: 0em;
			color: white;
			font-weight: bold;
			padding: 0.5em;
			@include flex('space-between', 'center');
			.FacilitatorSurveysPopup-title {
				span {
					font-size: 1.5em;
					font-weight: bold;
				}
			}
			.FacilitatorSurveysPopup-closeBtn {
				width: 2em;
				height: 2em;
				background-image: url('../../../assets/images/icons/icon-cross-white.svg');
				background-size: 1.25em auto;
				cursor: pointer;
				&:hover {
					background-size: 1.5em auto;
				}
			}
		}

		.FacilitatorSurveysPopup-body {
			padding: 2em 1em;
			text-align: left;
			.FacilitatorSurveysPopup-selectSurvey {
				width: 30em;
			}
			
		}
	}
}